@use "../global" as g;

//slick default style

.slick-dots {
  //bottom: -30px !important;
}

.slick-dots li {
  margin: 0 !important;

  @include g.mq-down(md) {
    width: 18px !important;
  }


  button {
    width: 14px !important;
    height: 14px !important;

    @include g.mq-down(md) {
      width: 10px !important;
      height: 10px !important;
    }
  }

  button:before {
    top: 6px !important;
    left: 6px !important;
    display: block;
    font-family: sans-serif !important;
    content: "" !important;
    color: g.$text-color !important;
    font-weight: 700;
    opacity: 1 !important;
    width: 14px !important;
    height: 14px !important;
    background-color: #5aa6e0;
    border-radius: 50%;
    border: 1px solid #5aa6e0;

    @include g.mq-down(md) {
      width: 10px !important;
      height: 10px !important;
    }

  }

  &.slick-active button:before {
    display: block;
    content: "" !important;
    background-color: g.$blue !important;
    border: 1px solid g.$blue !important;
  }
}

.slick-loading .slick-list {
  background: none !important;
}

.slick-prev:before,
.slick-next:before {
  content: "" !important;
  background-image: url(../images/common/btn_slidearrow.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 24px;
  height: 24px;
  display: block;
  opacity: 1 !important;


}

.slick-prev:before {
  transform: rotate(-180deg);
}

.slick-prev,
.slick-next {
  width: 24px !important;
  height: 24px !important;
  z-index: 1;


}

// slick スライドコントロールスタイル

.control-style1 {

  .slick-prev {
    top: calc(50% - 30px);
    left: -50px;

    @include g.mq-down(lg) {
      left: 20px;
    }
  }

  .slick-next {
    top: calc(50% - 30px);
    right: -50px;

    @include g.mq-down(lg) {
      right: 20px;
    }
  }

}
