@use "../global" as g;

.p-news {
  .article-sec {
    margin-bottom: 80px;
    position: relative;

    .inner {
      .list {
        .item {
          display: block;
          border-bottom: 1px solid #CCC;
          padding: 20px;
          transition: background-color .2s;

          @include g.mq-down(md) {
            padding: 10px 0px;
          }

          &:hover {
            @include g.mq-up(md) {
              background-color: rgba(g.$blue, $alpha: 0.05);
            }

          }

          &:last-of-type {
            @include g.mq-up(md) {
              border-bottom: none;
            }
          }

          dl {
            display: flex;
            align-items: start;
            font-size: 1.6rem;
            color: g.$blue;
            position: relative;

            @include g.mq-down(md) {
              justify-content: space-between;
              flex-wrap: wrap;
              font-size: 1.3rem;
            }

            dt {
              padding: 6px 0;
              margin-right: 15px;

              @include g.mq-down(md) {
                padding: 1px 0;
              }
            }

            dd {
              display: flex;
              align-items: start;

              &:nth-of-type(1) {
                margin-right: 15px;

                @include g.mq-down(md) {
                  margin-right: 0;
                }
              }

              &:nth-of-type(2) {
                @include g.mq-down(md) {
                  width: 100%;
                  margin-top: 10px;
                }
              }

              .cat {
                background: g.$yellow;
                padding: 6px 0;
                width: 200px;
                text-align: center;
                border-radius: 50px;

                @include g.mq-down(md) {
                  width: 100px;
                  padding: 1px 0;
                }
              }

              .title {
                font-size: 1.8rem;
                line-height: 2;
                padding: 0;
                font-weight: 500;
                margin-right: 15px;

                @include g.mq-down(md) {
                  font-size: 1.3rem;
                  line-height: 1.6;
                  font-weight: 700;
                }
              }

              .badge {
                border: 2px solid g.$red;
                border-radius: 50px;
                padding: 2px 20px;
                line-height: 1.8;
                color: g.$red;

                @include g.mq-down(md) {
                  padding: 0px 10px;
                  line-height: 1.3;
                }

              }
            }
          }
        }
      }

      .wp-pagenavi {
        margin-top: 60px;
        text-align: center;
        font-size: 1.6rem;

        @include g.mq-down(lg) {
          margin-top: 20px;
        }

        span {
          display: inline-block;
          border: none;
          color: g.$blue;
          z-index: 1;
          padding: 0 2px 5px;
          margin: 0 3px;
          border-bottom: 1px solid g.$blue;
          font-weight: 700;
        }

        a {
          border: none;
          color: g.$blue;
          padding: 0 2px 5px;
          margin: 0 3px;

          &:hover {
            font-weight: 700;
          }
        }
      }
    }


  }

  .parallax {
    .parallax1 {
      position: absolute;
      top: 70px;
      left: 50%;
      transform: translateX(-50%);
      width: 1600px;
      z-index: -1;

      @include g.mq-down(md) {
        top: -40px;
        width: calc(100% + 140px);
      }
    }

    .parallax2 {
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      width: 1480px;
      z-index: -2;

      @include g.mq-down(md) {
        display: none;
      }
    }
  }
}

.p-news-single {
  .post-sec {
    margin-bottom: 100px;
    position: relative;

    @include g.mq-down(lg) {
      margin-bottom: 50px;
    }

    .post-title {
      font-size: 2.1rem;
      letter-spacing: 1px;
      font-weight: 700;
      color: g.$blue;
      border-bottom: 1px solid #ccc;
      margin-bottom: 20px;
      padding-bottom: 20px;
      padding-left: 65px;
      padding-right: 65px;

      @include g.mq-down(lg) {
        padding-left: 0;
        padding-right: 0;
        font-size: 1.8rem;
      }
    }

    .date {
      text-align: right;
      color: g.$blue;
      margin-bottom: 30px;
    }

    .post {
      padding-left: 65px;
      padding-right: 65px;
      padding-bottom: 100px;

      @include g.mq-down(lg) {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 50px;
      }
    }

    .btn {
      display: block;
      background: g.$red;
      border-radius: 9999px;
      border: 2px solid g.$red;
      padding: 8px 30px;
      width: g.pcvw(474);
      max-width: 474px;
      margin: 0 auto;
      font-size: 1.6rem;
      text-align: center;
      letter-spacing: 1px;
      color: #fff;
      position: relative;
      transition: background-color .2s, color .2s;

      @include g.mq-down(lg) {
        width: 100%;
        max-width: none;
      }

      &::before {
        content: "";
        position: absolute;
        left: 20px;
        top: calc(50% - 4px);
        width: 8px;
        height: 8px;
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        transform: rotate(-135deg);
      }

      &:hover {
        background: #fff;
        color: g.$red;

        &::before {
          border-color: g.$red;
        }
      }
    }
  }

  .parallax {
    .parallax1 {
      @include g.mq-down(md) {
        top: 80px;
      }
    }
  }
}
