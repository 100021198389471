@use "../global" as g;

.recruit-sec {
  padding: 115px 0 160px;
  background-color: #fff;
  background-image:
    radial-gradient(#FFEB95 7px, transparent 7.5px),
    radial-gradient(#FFEB95 7px, transparent 7.5px);
  background-size: 40px 40px;
  background-position: 0 0, 20px 20px;

  @include g.mq-down(md) {
    padding: 60px 0;
    background-image:
      radial-gradient(#FFEB95 3.5px, transparent 3.75px),
      radial-gradient(#FFEB95 3.5px, transparent 3.75px);
    background-size: 20px 20px;
    background-position: 0 0, 10px 10px;
  }

  .midashi {
    margin-bottom: 40px;

    @include g.mq-down(md) {
      margin-bottom: 20px;
    }
  }

  .outline {
    font-size: 2.5rem;
    text-align: center;
    letter-spacing: 2px;
    margin-bottom: 40px;

    @include g.mq-down(md) {
      font-size: 1.3rem;
      margin-bottom: 20px;
    }
  }

  .box {
    display: flex;
    justify-content: center;
    align-items: center;

    .photo {
      width: g.pcvw(394 * 1.5);
      max-width: 394px;
      height: g.pcvw(394 * 1.5);
      max-height: 394px;
      margin-right: -40px;

      @include g.mq-down(md) {
        width: g.spvw(374);
        height: g.spvw(374);
        margin-right: -15px;
      }

      img {
        border-radius: 50%;
        border: 10px solid g.$yellow;

        @include g.mq-down(md) {
          border-width: 5px;
        }
      }
    }

    .text {
      background: g.$yellow;
      width: g.pcvw(394 * 1.5);
      max-width: 394px;
      height: g.pcvw(394 * 1.5);
      max-height: 394px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      @include g.mq-down(md) {
        width: g.spvw(374);
        height: g.spvw(374);
      }

      .btn {
        .icon {
          background: g.$orange;
          //border: 2px solid g.$orange;
        }

        &:hover {
          color: g.$orange;

          .icon {
            background-color: #fff;

            &::after {
              border-color: transparent transparent transparent g.$orange;
            }
          }
        }

        .icon2 {
          width: 15px;
          height: 15px;
          line-height: 1;
          margin-left: 5px;
        }
      }
    }
  }
}
