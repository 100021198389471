@use "../global" as g;

.p-amusement {
  .pagehead-sec {
    margin-bottom: 40px;

    @include g.mq-down(md) {
      margin-bottom: 10px;
    }

    .midashi {
      background: g.$blue;
      letter-spacing: 8px;

      @include g.mq-down(md) {
        letter-spacing: 5px;
      }

    }
  }

  .mv-sec {
    margin-bottom: 40px;
    position: relative;

    .inner {
      position: relative;

      .badge {
        width: 141px;
        position: absolute;
        top: -40px;
        right: 36px;

        @include g.mq-down(md) {
          width: 71px;
          top: 10px;
          right: -12.5px;

        }
      }

      .box {
        width: g.pcvw(1080);
        max-width: 1080px;
        height: g.pcvw(414);
        max-height: 414px;
        margin: 0 auto;
        position: relative;

        @include g.mq-down(md) {
          width: 100%;
          height: g.spvw(443);
        }

        .mv1 {
          mask-image: url("../images/amusement/mask_mv-01.svg");
          width: g.pcvw(386);
          max-width: 386px;
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: 1;

          @include g.mq-down(md) {
            width: g.spvw(333);
            left: -12.5px;
          }
        }

        .mv2 {
          mask-image: url("../images/amusement/mask_mv-02.svg");
          width: g.pcvw(524);
          max-width: 524px;
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);
          z-index: 0;

          @include g.mq-down(md) {
            width: g.spvw(453);
            bottom: g.spvw(50);
          }
        }

        .mv3 {
          mask-image: url("../images/amusement/mask_mv-03.svg");
          width: g.pcvw(403);
          max-width: 403px;
          position: absolute;
          right: 0;
          bottom: 0;
          z-index: 1;

          @include g.mq-down(md) {
            width: g.spvw(348);
            bottom: g.spvw(20);
            right: -12.5px;
          }
        }
      }
    }
  }

  .concept-sec {
    margin-bottom: 80px;
    position: relative;

    @include g.mq-down(md) {
      margin-bottom: 50px;
    }

    .inner {
      .copy {
        font-size: 4.2rem;
        font-weight: 700;
        letter-spacing: 16px;
        color: g.$blue;
        margin-bottom: 20px;
        text-align: center;

        @include g.mq-down(md) {
          font-size: 1.6rem;
          letter-spacing: 6px;
        }
      }

      .read {
        font-size: 1.7rem;
        letter-spacing: 2px;
        line-height: 2.4;
        text-align: center;
        margin-bottom: 100px;

        @include g.mq-down(md) {
          font-size: 1.3rem;
          line-height: 1.8;
          margin-bottom: 50px;
        }

        span {
          color: g.$blue;
        }
      }

      .photo {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 80px;

        @include g.mq-down(md) {
          margin-bottom: 40px;
        }

        .img {
          //padding: 0 min(g.pcvw(10), 10px);
          padding: 0 min(g.pcvw(27), 27px);
          margin-bottom: 40px;

          &:nth-last-of-type(1) {
            margin-bottom: 0;
          }

          @include g.mq-down(md) {
            width: 48%;
            margin-bottom: min(g.pcvw(53), 53px);

          }

          figure {
            width: g.pcvw(447 * 1.3);
            max-width: 447px;

            @include g.mq-down(md) {
              width: 100%;
            }



            img {
              border-radius: 15px;
              @include g.boxshadow();

              @include g.mq-down(md) {
                border-radius: 5px;
              }
            }
          }
        }
      }

      .btn {
        display: block;
        width: 328px;
        background: g.$blue;
        border: 3px solid g.$blue;
        color: #FFF;
        text-align: center;
        font-size: 1.8rem;
        font-weight: 700;
        letter-spacing: 7px;
        padding: 12px 0;
        margin: 0 auto;
        border-radius: 50px;
        position: relative;
        transition: background .2s, color .2s;

        @include g.mq-down(md) {
          width: 70%;
          padding: 6px 0;
          font-size: 1.4rem;
        }

        span {
          position: absolute;
          top: 50%;
          left: 30px;
          transform: translateY(-50%);

          @include g.mq-down(md) {
            left: 10px;
          }

          svg {
            width: 30px;
            fill: #fff;
            transition: fill .2s;

            @include g.mq-down(md) {
              width: 15px;
            }
          }
        }

        &:hover {
          @include g.mq-up(md) {
            background: #fff;
            color: g.$blue;

            span {
              svg {
                fill: g.$blue;
              }
            }
          }

        }
      }
    }

    .parallax {
      .parallax1 {
        position: absolute;
        top: 350px;
        left: 50%;
        transform: translateX(-50%);
        width: 1480px;
        z-index: -2;

        @include g.mq-down(md) {
          display: none;
        }
      }

      .parallax2 {
        position: absolute;
        top: 400px;
        left: 50%;
        transform: translateX(-50%);
        width: 1366px;
        z-index: -1;

        @include g.mq-down(md) {
          top: 450px;
          width: 100%;
        }
      }
    }
  }

  .unmannedstore-sec {
    padding: 70px 0 100px;
    @include g.dot-bg();
    position: relative;

    @include g.mq-down(md) {
      padding: 50px 0;
    }

    .inner {
      position: relative;
      z-index: 1;

      .midashi {
        margin-bottom: 70px;

        @include g.mq-down(md) {
          margin-bottom: 40px;
        }
      }

      .copy {
        font-size: 4.2rem;
        font-weight: 700;
        letter-spacing: 16px;
        color: g.$blue;
        margin-bottom: 50px;
        text-align: center;

        @include g.mq-down(md) {
          font-size: 1.6rem;
          letter-spacing: 6px;
          margin-bottom: 30px;
        }
      }

      .read {
        font-size: 1.7rem;
        letter-spacing: 2px;
        line-height: 2.4;
        text-align: center;
        margin-bottom: 100px;

        @include g.mq-down(md) {
          font-size: 1.3rem;
          line-height: 1.8;
          margin-bottom: 20px;
        }
      }

      .photo {
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;

        @include g.mq-down(md) {
          flex-wrap: wrap;
          margin-bottom: 10px;
        }

        .img {
          width: 31.89%;

          @include g.mq-down(md) {
            width: 48%;

            &:nth-of-type(1) {
              margin: 0 26% 15px;
            }
          }

          img {
            border-radius: 10px;
            @include g.boxshadow();

            @include g.mq-down(md) {
              border-radius: 5px;
            }
          }
        }
      }

      .branch {
        font-size: 1.7rem;
        line-height: 2.3;

        @include g.mq-down(md) {
          font-size: 1.3rem;
        }
      }
    }

    .parallax {
      .parallax1 {
        position: absolute;
        top: 100px;
        left: 50%;
        transform: translateX(-50%);
        width: 1620px;
        z-index: 0;

        @include g.mq-down(md) {
          display: none;
        }
      }

      .parallax2 {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 1366px;
        z-index: 0;

        @include g.mq-down(md) {
          top: 750px;
          width: 100%;
        }
      }
    }
  }

  .contact-sec {
    padding: 80px 0 0;
    position: relative;

    @include g.mq-down(md) {
      padding: 50px 0 0;
    }

    .inner {
      .read {
        font-size: 4.1rem;
        font-weight: 700;
        color: g.$red;
        text-align: center;
        letter-spacing: 17px;
        margin-bottom: 50px;

        @include g.mq-down(md) {
          font-size: 2rem;
          letter-spacing: 8px;
          margin-bottom: 30px;
        }

        &::after {
          content: "";
          display: block;
          width: 575px;
          height: 15px;
          background-image: url(../images/amusement/obj_contact-01.svg);
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          margin: 10px auto 0;
          position: relative;
          left: -10px;

          @include g.mq-down(md) {
            width: 300px;
            height: g.spvw(16);
            left: 0;
            margin: 5px auto 0;
          }
        }
      }

      .btn {
        display: block;
        width: 474px;
        background: g.$red;
        border: 3px solid g.$red;
        color: #FFF;
        text-align: center;
        font-size: 1.8rem;
        font-weight: 700;
        letter-spacing: 5px;
        padding: 12px 0;
        margin: 0 auto;
        border-radius: 50px;
        position: relative;
        transition: background .2s, color .2s;

        @include g.mq-down(md) {
          width: 100%;
          padding: 6px 0;
          letter-spacing: 2px;
          font-size: 1.4rem;
          margin-bottom: 20px;
        }

        span {
          position: absolute;
          top: 50%;
          left: 30px;
          transform: translateY(-50%);

          @include g.mq-down(md) {
            left: 10px;
          }

          svg {
            width: 40px;
            fill: #fff;
            transition: fill .2s;

            @include g.mq-down(md) {
              width: 20px;
            }
          }
        }

        &:hover {
          background: #fff;
          color: g.$red;

          span {
            svg {
              fill: g.$red;
            }
          }
        }
      }
    }

    .parallax {
      .parallax1 {
        position: absolute;
        top: 150px;
        left: 50%;
        transform: translateX(-50%);
        width: 1620px;
        z-index: -1;

        @include g.mq-down(md) {
          top: 160px;
          width: calc(100% + 175px);
        }
      }
    }
  }

  .bg {
    background-image: url(../images/about/bg-01_pc.svg);
    background-position: center top;
    background-repeat: repeat-y;
    background-size: 1560px;
    position: relative;
    padding-bottom: 180px;

    @include g.mq-down(md) {
      padding-bottom: 0px;
      background-image: url(../images/about/bg-01_sp.svg);
      background-size: 100%;
    }
  }
}
