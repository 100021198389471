@use "../global" as g;

.p-contact {

  .pagehead-sec {
    margin-bottom: 50px;
  }

  .form-sec {
    padding-bottom: 100px;
    position: relative;

    @include g.mq-down(md) {
      padding-bottom: 50px;
    }

    .outlineArea {

      padding-bottom: 50px;

      .inner {
        .intro {
          color: g.$blue;
          text-align: center;
          font-size: 1.7rem;
          line-height: 2.3;
          font-weight: 700;
          margin-bottom: 20px;

          @include g.mq-down(md) {
            font-size: 1.4rem;
            line-height: 2;
          }
        }

        .read {
          font-size: 1.7rem;
          line-height: 2.3;
          text-align: center;

          @include g.mq-down(md) {
            font-size: 1.4rem;
            line-height: 2;
          }
        }

        .confirm {
          display: none;
          font-size: 1.7rem;
          color: g.$red;
          font-weight: 700;
          text-align: center;
        }
      }
    }

    .formArea {
      .inner {
        .mailform {
          .row {
            dt {
              font-size: 1.7rem;

              @include g.mq-down(md) {
                font-size: 1.5rem;
              }
            }

            dd {
              .note {
                margin-top: 2px;
              }
            }
          }
        }
      }
    }

    .btnArea {
      .inner {
        .btn {
          display: flex;
          justify-content: center;

          .reset_btn,
          .back_btn {
            display: inline-block;
            background: g.$blue;
            border: 2px solid g.$blue;
            color: #fff;
            width: 100%;
            max-width: 180px;
            text-align: center;
            padding: 10px 0px;
            border-radius: 9999px;
            letter-spacing: 4px;
            text-align: center;
            position: relative;
            margin: 0 20px;
            transition: background-color .2s, color .2s;

            @include g.mq-down(md) {
              max-width: 280px;
            }

            &:hover {
              background: #fff;
              color: g.$blue;
            }
          }

          .submit_btn {
            display: inline-block;
            background: g.$red;
            border: 2px solid g.$red;
            color: #fff;
            width: 100%;
            max-width: 180px;
            padding: 10px 0px;
            border-radius: 9999px;
            letter-spacing: 4px;
            text-align: center;
            position: relative;
            margin: 0 20px;
            transition: background-color .2s, color .2s;

            @include g.mq-down(md) {
              max-width: 280px;
            }

            &::after {
              content: "";
              display: block;
              position: absolute;
              top: calc(50% - 4px);
              right: 20px;
              transform: translateY(-50%);
              transform: rotate(45deg);
              width: 8px;
              height: 8px;
              border-top: 2px solid #fff;
              border-right: 2px solid #fff;

              transition: border-color .2s;

              @include g.mq-down(md) {
                right: 20px;
                width: 8px;
                height: 8px;
                top: calc(50% - 4px);
              }
            }

            &:hover {
              background: #fff;
              color: g.$red;

              &::after {
                border-color: g.$red;
              }
            }
          }
        }
      }
    }

    .parallax {

      .parallax1 {
        width: 1600px;
        position: absolute;
        top: 0px;
        left: 50%;
        transform: translateX(-50%);
        z-index: -2;

        @include g.mq-down(md) {
          top: 70px;
          z-index: -3;
          width: calc(g.spvw(750) + 175px);
        }
      }

      .parallax2 {
        width: 1480px;
        position: absolute;
        top: 70px;
        left: 50%;
        transform: translateX(-50%);
        z-index: -3;

        @include g.mq-down(md) {
          z-index: -2;
          width: calc(g.spvw(750) + 45px);
          top: 140px;
        }
      }
    }
  }
}

.p-contact-thanks {
  .form-sec {
    .thanks-message {
      .inner {
        position: relative;

        .intro {
          text-align: center;
          font-size: 2.3rem;
          letter-spacing: 4px;
          font-weight: 700;
          color: g.$blue;
          margin-bottom: 30px;
        }

        .read {
          text-align: center;
          font-size: 1.6rem;
          line-height: 2.2;
          letter-spacing: 3px;
          color: g.$blue;
          margin-bottom: 60px;

          @include g.mq-down(md) {
            font-size: 1.4rem;
            line-height: 1.8;
            letter-spacing: 1px;
            margin-bottom: 40px;
          }
        }

        small {
          display: block;
          font-size: 1.4rem;
          line-height: 2;
          text-align: center;
          margin-bottom: 60px;

          @include g.mq-down(md) {
            font-size: 1.3rem;
            margin-bottom: 40px;
          }

          a {
            color: g.$blue;
            text-decoration: underline;
          }
        }

        .btn {
          display: block;
          background: g.$red;
          border-radius: 9999px;
          border: 2px solid g.$red;
          padding: 8px 30px;
          width: g.pcvw(474);
          max-width: 474px;
          margin: 0 auto;
          font-size: 1.6rem;
          text-align: center;
          letter-spacing: 1px;
          color: #fff;
          position: relative;
          transition: background-color .2s, color .2s;

          @include g.mq-down(lg) {
            width: calc(100% - 25px);
            max-width: none;
          }

          &::before {
            content: "";
            position: absolute;
            right: 20px;
            top: calc(50% - 4px);
            width: 8px;
            height: 8px;
            border-top: 2px solid #fff;
            border-right: 2px solid #fff;
            transform: rotate(45deg);
          }

          &:hover {
            background: #fff;
            color: g.$red;

            &::before {
              border-color: g.$red;
            }
          }
        }

        .object {
          @include g.mq-down(md) {
            display: none;
          }

          .obj1 {
            $obj1-w: 180;
            $obj1-h: 189;
            position: absolute;
            z-index: 2;
            top: min(g.pcvw(-100), -100px);
            left: min(g.pcvw(0), 0px);
            max-width: 1px * $obj1-w;
            width: g.pcvw($obj1-w * 1.3);
            max-height: 1px * $obj1-h;
            height: g.pcvw($obj1-h * 1.3);

            @include g.mq-down(md) {
              top: min(g.pcvw(0), 0px);
            }
          }

          .obj2 {
            $obj2-w: 180;
            $obj2-h: 189;
            position: absolute;
            z-index: 2;
            top: min(g.pcvw(-100), -100px);
            right: min(g.pcvw(0), 0px);
            max-width: 1px * $obj2-w;
            width: g.pcvw($obj2-w * 1.3);
            max-height: 1px * $obj2-h;
            height: g.pcvw($obj2-h * 1.3);

            @include g.mq-down(md) {
              display: none;
            }
          }

          .obj3 {
            $obj3-w: 180;
            $obj3-h: 189;
            position: absolute;
            z-index: 2;
            top: min(g.pcvw(200), 200px);
            left: min(g.pcvw(60), 60px);
            max-width: 1px * $obj3-w;
            width: g.pcvw($obj3-w * 1.3);
            max-height: 1px * $obj3-h;
            height: g.pcvw($obj3-h * 1.3);

            @include g.mq-down(md) {
              top: auto;
              bottom: min(g.pcvw(-10), -10px);
              left: 0;
            }
          }

          .obj4 {
            $obj4-w: 180;
            $obj4-h: 189;
            position: absolute;
            z-index: 2;
            top: min(g.pcvw(230), 230px);
            right: min(g.pcvw(40), 40px);
            max-width: 1px * $obj4-w;
            width: g.pcvw($obj4-w * 1.3);
            max-height: 1px * $obj4-h;
            height: g.pcvw($obj4-h * 1.3);

            @include g.mq-down(md) {
              top: auto;
              bottom: min(g.pcvw(-10), -10px);
              right: 10px;
            }
          }
        }
      }
    }
  }
}
