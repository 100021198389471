@use "../global" as g;

.wrapper {
  overflow: hidden;
}

.lower {
  main {
    margin-top: 40px;

    @include g.mq-down(md) {
      margin-top: 20px;
    }
  }
}

.ly_w1080 {
  width: calc(100% - 50px);
  max-width: 1080px;
  margin: 0 auto;
}

.ly_w950 {
  width: calc(100% - 50px);
  max-width: 950px;
  margin: 0 auto;
}

//facility-layout
.facility-list {
  .item {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding-bottom: 50px;
    border-bottom: 4px dotted g.$light-blue;
    margin-bottom: 50px;

    @include g.mq-down(md) {
      display: block;
      padding-bottom: 40px;
      margin-bottom: 40px;
    }

    &:nth-last-of-type(1) {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
    }

    .photo {
      width: percentage(405px / 950px);
      display: flex;
      justify-content: space-between;
      align-self: baseline;
      flex-wrap: wrap;

      @include g.mq-down(md) {
        width: 100%;
      }

      .img {
        width: 32%;

        &:nth-of-type(1) {
          width: 100%;
          margin-bottom: 10px;
        }

        img {
          border-radius: 15px;
          @include g.boxshadow();
        }
      }
    }

    .text {
      width: calc(100% - percentage(435px / 950px));

      @include g.mq-down(md) {
        width: 100%;
        margin-bottom: 40px;
      }

      .name {
        font-size: 2.2rem;
        font-weight: 700;
        letter-spacing: 2px;
        color: g.$dark-orange;
        margin-bottom: 35px;

        @include g.mq-down(md) {
          font-size: 1.7rem;
        }
      }

      .info {
        dl {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin-bottom: 15px;

          &.hp-link,
          &.sns {
            align-items: center;

            dd {
              small {
                font-size: 1.15rem;
              }

              a {
                color: g.$light-blue;
                text-decoration: underline;
              }
            }
          }

          &.sns {
            dd {
              display: flex;
              flex-wrap: wrap;

              a {
                margin-right: 10px;
              }
            }
          }

          dt {
            width: 95px;
            color: g.$dark-orange;
            font-weight: 700;
            border: 1px solid g.$dark-orange;
            border-radius: 10px;
            background: #fff;
            padding: 2px 0;
            text-align: center;
            font-size: 1.2rem;
            letter-spacing: 0.5px;

          }

          dd {
            width: calc(100% - 110px);
            padding: 2px 0;

            a {
              color: g.$dark-orange;
              text-decoration: underline;
            }

            .banner {
              display: block;
              width: 100%;
              max-width: 236px;
            }

            .sns-icon {
              display: block;
              width: 36px;
            }
          }
        }
      }
    }
  }
}
