@use "../global" as g;

.p-404 {
  .mv-sec {
    margin-top: -30px;
    margin-bottom: 30px;

    @include g.mq-down(md) {
      margin-top: 0;
    }

    .inner {
      position: relative;

      @include g.mq-down(md) {
        width: calc(100% - 20px);
      }

      .mv {
        figure {
          width: 329px;
          margin: 100px auto 40px;

          @include g.mq-down(md) {
            margin: 40px auto 20px;
            width: g.spvw(470);
          }
        }

        p {
          color: g.$blue;
          font-weight: 700;
          text-align: center;
          font-size: 2rem;
          margin-bottom: 100px;

          @include g.mq-down(md) {
            font-size: 1.8rem;
            margin-bottom: 50px;
          }
        }

        .btn {
          display: block;
          background: g.$red;
          border-radius: 9999px;
          border: 2px solid g.$red;
          padding: 8px 30px;
          width: g.pcvw(474);
          max-width: 474px;
          margin: 0 auto;
          font-size: 1.6rem;
          text-align: center;
          letter-spacing: 1px;
          color: #fff;
          position: relative;
          transition: background-color .2s, color .2s;

          @include g.mq-down(lg) {
            width: calc(100% - 25px);
            max-width: none;
          }

          &::before {
            content: "";
            position: absolute;
            left: 20px;
            top: calc(50% - 4px);
            width: 8px;
            height: 8px;
            border-top: 2px solid #fff;
            border-right: 2px solid #fff;
            transform: rotate(-135deg);
          }

          &:hover {
            background: #fff;
            color: g.$red;

            &::before {
              border-color: g.$red;
            }
          }
        }
      }

      .object {
        .obj1 {
          $obj1-w: 117;
          $obj1-h: 186;
          position: absolute;
          z-index: 2;
          top: min(g.pcvw(-50), -50px);
          left: min(g.pcvw(70), 70px);
          max-width: 1px * $obj1-w;
          width: g.pcvw($obj1-w * 1.3);
          max-height: 1px * $obj1-h;
          height: g.pcvw($obj1-h * 1.3);

          @include g.mq-down(md) {
            top: min(g.pcvw(-40), -40px);
          }
        }

        .obj2 {
          $obj2-w: 152;
          $obj2-h: 124;
          position: absolute;
          z-index: 2;
          top: min(g.pcvw(0), 0px);
          right: min(g.pcvw(90), 90px);
          max-width: 1px * $obj2-w;
          width: g.pcvw($obj2-w * 1.3);
          max-height: 1px * $obj2-h;
          height: g.pcvw($obj2-h * 1.3);

          @include g.mq-down(md) {
            top: min(g.pcvw(-40), -40px);
            right: 10px;
          }
        }

        .obj3 {
          $obj3-w: 209;
          $obj3-h: 181;
          position: absolute;
          z-index: 2;
          top: min(g.pcvw(200), 200px);
          left: min(g.pcvw(-40), -40px);
          max-width: 1px * $obj3-w;
          width: g.pcvw($obj3-w * 1.3);
          max-height: 1px * $obj3-h;
          height: g.pcvw($obj3-h * 1.3);

          @include g.mq-down(md) {
            top: auto;
            bottom: min(g.pcvw(520), 520px);
            left: 0;
          }
        }

        .obj4 {
          $obj4-w: 155;
          $obj4-h: 219;
          position: absolute;
          z-index: 2;
          top: min(g.pcvw(200), 200px);
          right: min(g.pcvw(-25), -25px);
          max-width: 1px * $obj4-w;
          width: g.pcvw($obj4-w * 1.3);
          max-height: 1px * $obj4-h;
          height: g.pcvw($obj4-h * 1.3);

          @include g.mq-down(md) {
            top: auto;
            bottom: min(g.pcvw(500), 500px);
            right: 10px;
          }
        }
      }

      .parallax {

        .parallax1 {
          width: 1600px;
          position: absolute;
          top: 60px;
          left: 50%;
          transform: translateX(-50%);
          z-index: -2;

          @include g.mq-down(md) {
            z-index: -3;
            width: calc(g.spvw(750) + 150px);
          }
        }

        .parallax2 {
          width: 1440px;
          position: absolute;
          top: 170px;
          left: 50%;
          transform: translateX(-50%);
          z-index: -3;

          @include g.mq-down(md) {
            z-index: -2;
            width: calc(g.spvw(750) + 10px);
            top: calc(50% - 50px);
          }
        }
      }
    }
  }
}
