@use "../breakpoint" as b;
$text-color: #000;
$blue: #1059A6;
$light-blue: #4FAAE0;
$red: #FF5E73;
$pink: #FF5182;
$green: #00B94C;
$dark-orange: #FF501E;
$orange: #FF903B;
$yellow: #F9CF00;
$purple: #8D6DF4;
$white: #FFFFFF;
$sub-grad: linear-gradient(90deg, #5aa6e0 0%, #74bce6 100%);
$main-grad: linear-gradient(90deg, #69B8FC 0%, #3553E5 100%);
