.inview,
.inviewL,
.inviewR,
.inviewScaleR {
  opacity: 0;
}

$move :40px;
$moveBig :240px;
$duration : .8s;


.fadeInUp {
  animation-name: fadeInUp;
  animation-duration: $duration;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, $move, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInBigUp {
  animation-name: fadeInBigUp;
  animation-duration: $duration;
  animation-fill-mode: both;
}

@keyframes fadeInBigUp {
  from {
    opacity: 0;
    transform: translate3d(0, $moveBig, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInRight {
  animation-name: fadeInRight;
  animation-duration: $duration;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

@keyframes fadeInRight {
  from {
    opacity: 0;

    transform: translate3d($move, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
  animation-duration: $duration;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-$move, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.scaleInRight {
  animation: scaleInRight 2.6s cubic-bezier(0.22, 1, 0.36, 1) forwards;
  transform-origin: right center;

}

@keyframes scaleInRight {
  0% {
    opacity: 0;
    transform: scaleX(0) translateX(-5%);
  }

  30% {
    transform: scaleX(1) translateX(0);
  }

  100% {
    transform: scaleX(1) translateX(0);
  }

  30%,
  100% {
    opacity: 1;
  }
}

.delay_1 {

  animation-delay: 0.1s;
}

.delay_2 {

  animation-delay: 0.2s;
}

.delay_3 {

  animation-delay: 0.3s;
}

.delay_4 {

  animation-delay: 0.4s;
}

.delay_5 {

  animation-delay: 0.5s;
}

.delay_6 {

  animation-delay: 0.6s;
}

.delay_7 {

  animation-delay: 0.7s;
}

.delay_8 {

  animation-delay: 0.8s;
}

.delay_9 {

  animation-delay: 0.9s;
}

.delay_10 {

  animation-delay: 1s;
}

.delay_20 {

  animation-delay: 2s;
}

.delay_30 {

  animation-delay: 3s;
}

.delay_40 {

  animation-delay: 4s;
}

.delay_50 {

  animation-delay: 5s;
}

.delay_60 {

  animation-delay: 6s;
}


// 上下にふわふわアニメーション
.fuwafuwa1-vertical {
  animation-name: fuwafuwa1-vertical-keyframe;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: 1.5s;
}

// 上下にふわふわアニメーション
.fuwafuwa2-vertical {
  animation-name: fuwafuwa1-vertical-keyframe;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: 2.5s;
}

// 上下にふわふわアニメーション
.fuwafuwa3-vertical {
  animation-name: fuwafuwa1-vertical-keyframe;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: 3.5s;
}

// 上下にふわふわアニメーション
.fuwafuwa4-vertical {
  animation-name: fuwafuwa1-vertical-keyframe;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: 2.5s;
}

@keyframes fuwafuwa1-vertical-keyframe {
  0% {
    transform: translate(0, 0px);
  }

  100% {
    transform: translate(0, -15px)
  }
}



/*マスク（非表示状態）*/
.mask-hide {
  //mask-image: url("../images/top/mask.svg");
  mask-repeat: no-repeat;
  mask-position: 50% 50%;
  mask-size: 0% 0%;
  transform: translate3d(0, 0%, 0);
  will-change: mask-size, transform;
}

/*マスク（表示状態）*/
.mask-show {
  transition: transform 1000ms cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-mask-size 1000ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: translate3d(0, 0%, 0);
  mask-size: 99% 99%;
  transition-delay: 300ms;
}
