@use "../global" as g;

.p-indoor-playground {
  .pagehead-sec {
    margin-bottom: 0px;

    @include g.mq-down(md) {
      margin-bottom: 0px;
    }

    .midashi {
      background: g.$dark-orange;
    }
  }

  .mv-sec {
    margin-bottom: 40px;
    position: relative;

    .inner {
      position: relative;

      .box {
        width: 100%;
        max-width: 950px;
        //margin: 0 auto;

        @include g.mq-down(md) {
          margin-left: -12.5px;
          width: calc(100% + 25px);
        }

        .mv1 {
          mask-image: url("../images/indoor-playground/mask_mv-01.svg");
          width: 100%;
        }
      }
    }
  }

  .concept-sec {
    margin-bottom: 80px;
    position: relative;

    @include g.mq-down(md) {
      margin-bottom: 50px;
    }

    .inner {
      .copy {
        font-size: 4.2rem;
        font-weight: 700;
        letter-spacing: 16px;
        color: g.$blue;
        margin-bottom: 20px;
        text-align: center;

        @include g.mq-down(md) {
          font-size: 1.6rem;
          letter-spacing: 6px;
          margin-left: -12.5px;
          width: calc(100% + 25px);
        }
      }

      .read {
        font-size: 1.7rem;
        letter-spacing: 2px;
        line-height: 2.4;
        text-align: center;
        margin-bottom: 60px;

        @include g.mq-down(md) {
          font-size: 1.3rem;
          line-height: 1.8;
          margin-bottom: 30px;
        }

        span {
          color: g.$blue;
        }
      }

      .btn {
        display: block;
        width: 328px;
        background: g.$blue;
        border: 3px solid g.$blue;
        color: #FFF;
        text-align: center;
        font-size: 1.8rem;
        font-weight: 700;
        letter-spacing: 7px;
        padding: 12px 0;
        margin: 0 auto;
        border-radius: 50px;
        position: relative;
        transition: background .2s, color .2s;

        @include g.mq-down(md) {
          width: 70%;
          padding: 6px 0;
          font-size: 1.4rem;
        }

        span {
          position: absolute;
          top: 50%;
          left: 30px;
          transform: translateY(-50%);

          @include g.mq-down(md) {
            left: 10px;
          }

          svg {
            width: 30px;
            fill: #fff;
            transition: fill .2s;

            @include g.mq-down(md) {
              width: 15px;
            }
          }
        }

        &:hover {
          @include g.mq-up(md) {
            background: #fff;
            color: g.$blue;

            span {
              svg {
                fill: g.$blue;
              }
            }
          }

        }
      }
    }
  }

  .mamasmile-sec {
    padding: 70px 0 100px;
    position: relative;

    @include g.mq-down(md) {
      padding: 50px 0;
    }

    .inner {
      position: relative;
      z-index: 1;

      .midashi {
        margin-bottom: 70px;
        color: g.$dark-orange;
        border-color: g.$dark-orange;

        @include g.mq-down(md) {
          margin-bottom: 40px;
          letter-spacing: 4px;
        }
      }

      .facility-list {
        @include g.facility(g.$dark-orange);
      }
    }

    .parallax {
      .parallax1 {
        position: absolute;
        top: 100px;
        left: 50%;
        transform: translateX(-50%);
        width: 1680px;
        z-index: 0;

        @include g.mq-down(md) {
          display: none;
        }
      }

      .parallax2 {
        position: absolute;
        top: 200px;
        left: 50%;
        transform: translateX(-50%);
        width: 1366px;
        z-index: 0;

        @include g.mq-down(md) {
          display: none;
        }
      }
    }
  }

  .kidssmile-sec {
    padding: 70px 0 100px;
    @include g.dot-bg(g.$yellow, 0.2);
    position: relative;

    .inner {
      position: relative;
      z-index: 1;

      .midashi {
        margin-bottom: 70px;
        color: g.$dark-orange;
        border-color: g.$dark-orange;

        @include g.mq-down(md) {
          margin-bottom: 40px;
          letter-spacing: 4px;
        }
      }

      .facility-list {
        @include g.facility(g.$dark-orange);
      }
    }

    .parallax {
      .parallax1 {
        position: absolute;
        top: 500px;
        left: 50%;
        transform: translateX(-50%);
        width: 1480px;
        z-index: 0;

        @include g.mq-down(md) {
          display: none;
        }
      }

      .parallax2 {
        position: absolute;
        top: 100px;
        left: 50%;
        transform: translateX(-50%);
        width: 1560px;
        z-index: 0;

        @include g.mq-down(md) {
          display: none;
        }
      }
    }
  }

  .bg {
    background-image: url(../images/common/bg.svg);
    background-position: center top;
    background-repeat: repeat-y;
    background-size: 1560px;
    position: relative;

    @include g.mq-down(md) {
      padding-bottom: 0px;
      background-image: url(../images/common/bg_sp.svg);
      background-size: 100%;
    }
  }
}
