@use "../global" as g;

.p-recruit {
  main {
    margin-top: 0 !important;
  }

  .mv-sec {
    //margin-top: -30px;
    margin-bottom: 30px;
    position: relative;

    @include g.mq-down(md) {
      margin-top: 0;
      margin-bottom: 0;
    }

    .inner {

      .copy {
        position: absolute;
        top: 180px;
        right: calc(50vw - 950px / 2);
        z-index: 100;
        text-align: right;

        @include g.mq-down(lg) {
          right: 40px;
        }

        @include g.mq-down(md) {
          right: 0;
          top: g.spvw(200);

        }

        p {
          display: inline-block;
          font-size: min(g.pcvw(31 * 1.3), 3.1rem);
          font-weight: 700;
          color: #fff;
          letter-spacing: min(g.pcvw(16 * 1.3), 16px);
          padding: min(g.pcvw(10 * 1.3), 10px) min(g.pcvw(20 * 1.3), 20px);

          //padding: 10px 20px;
          &:nth-of-type(1) {
            margin-bottom: 10px;

            @include g.mq-down(md) {
              margin-bottom: g.spvw(10);
            }
          }

          @include g.mq-down(md) {
            font-size: g.spvw(24);
            right: 0px;
            letter-spacing: 10px;
            padding: 7px 14px;
          }

          &:nth-of-type(1) {
            background: g.$sub-grad;
            top: min(g.pcvw(30 * 1.3), 30px);

            @include g.mq-down(md) {
              top: 0px;
            }
          }

          &:nth-of-type(2) {
            background: g.$main-grad;
            top: min(g.pcvw(120 * 1.3), 120px);

            @include g.mq-down(md) {
              top: 45px;
            }
          }
        }
      }

      .mv {
        width: 100%;
        height: 768px;
        position: relative;

        @include g.mq-down(md) {
          height: g.spvw(497);
        }

        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 0px;
          left: 0;
          z-index: 1;
          width: 100%;
          height: 100%;
          background-image: url(../images/recruit/frame_mv-01.svg);
          background-repeat: repeat-x;
          background-position: center;
          background-size: 1366px 768px;

          @include g.mq-down(md) {
            background-size: 100%;
          }
        }

        &:after {
          content: "";
          display: block;
          position: absolute;
          bottom: -1px;
          left: 0;
          width: 100%;
          height: 1px;
          background: #fff;
          z-index: 0;
        }

        figure {
          width: 100%;
          height: 100%;
          @include g.imgFit();

          img {
            object-position: center 10%;

            @include g.mq-down(md) {
              object-position: center top;
            }
          }
        }

        @include g.mq-down(md) {
          width: 100%;
          height: auto;
        }
      }

      .catch {
        width: calc(100% - 40px);
        max-width: 1161px;
        margin: -265px auto 0;
        position: relative;
        z-index: 5;

        @include g.mq-down(md) {
          margin: -20px auto 0;
        }
      }
    }

    .parallax {
      position: absolute;
      margin: 0 auto;
      top: 0;
      left: 50%;
      z-index: 3;
      margin-left: -540px;

      @include g.mq-down(md) {
        position: static;
      }

      .parallax1 {
        width: 1600px;
        position: absolute;
        top: 120px;
        left: 50%;
        transform: translateX(-50%);
        z-index: -2;

        @include g.mq-down(md) {
          width: g.spvw(1086);
          z-index: 1;
          top: 80px;
        }
      }

      .parallax2 {
        width: 1440px;
        position: absolute;
        top: 300px;
        left: 50%;
        transform: translateX(-50%);
        z-index: -3;

        @include g.mq-down(md) {
          width: g.spvw(780);
          top: 90%;
          z-index: 1;
        }
      }
    }
  }

  .outline-sec {
    margin-top: -80px;
    margin-bottom: 80px;

    @include g.mq-down(md) {
      margin-top: 0;
    }

    .inner {
      position: relative;

      .read {
        text-align: center;
        font-size: 1.7rem;
        line-height: 2.4;
        letter-spacing: 3px;
        font-weight: 700;

        @include g.mq-down(md) {
          font-size: 1.5rem;
          line-height: 2;
          letter-spacing: 1px;
        }
      }

      .parallax {

        .parallax1 {
          width: 1662px;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          z-index: -2;

          @include g.mq-down(md) {
            width: g.spvw(750);
          }
        }

        .parallax2 {
          width: 1440px;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          z-index: -3;

          @include g.mq-down(md) {
            width: g.spvw(750);
            top: calc(50% + 40px);
          }
        }
      }
    }
  }

  .formlink-sec {
    padding: 60px 0;
    background-color: #fcd600;
    background-image: radial-gradient(#F9CB22 20%, transparent 20%), radial-gradient(#F9CB22 20%, transparent 20%);
    background-position: 0 0, 20px 20px;
    background-size: 40px 40px;

    @include g.mq-down(md) {
      padding: 40px 0;
    }

    a {
      display: block;
      width: 100%;
      height: 100%;
      color: g.$blue;
      font-size: 2.5rem;
      letter-spacing: 10px;
      font-weight: 700;
      text-align: center;
      position: relative;

      @include g.mq-down(md) {
        font-size: 1.8rem;
        letter-spacing: 4px;
      }

      span {
        position: relative;
        padding-right: 25px;
        z-index: 1;

        &::before {
          content: "";
          position: absolute;
          top: calc(50% - 3px);
          right: 0px;
          box-sizing: border-box;
          width: 4px;
          height: 4px;
          border: 4px solid transparent;
          border-left: 8px solid g.$blue;
        }
      }

      .background-txt {
        width: 642px;
        height: 111px;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 0;
        transform: translate(-50%, -50%);

        @include g.mq-down(md) {
          width: calc(100% - 40px);
          max-width: 370px;
          height: auto;
        }
      }
    }
  }
}


.content-sec {
  background-image: url(../images/common/bg.svg);
  background-position: center 250px;
  background-repeat: repeat-y;
  background-size: 1506px;
  position: relative;

  @include g.mq-down(md) {
    padding-bottom: 0px;
    background-image: url(../images/common/bg_sp.svg);
    background-size: 100%;
  }

  padding: 90px 0 150px;
  position: relative;

  @include g.mq-down(md) {
    padding: 40px 0 80px;
  }

  .inner {
    position: relative;
    z-index: 2;

    .midashi {
      margin-bottom: 75px;
    }

    .tab {
      display: flex;
      justify-content: space-between;

      li {
        width: 32%;
        max-width: 300px;
        margin-bottom: 20px;

        @include g.mq-down(md) {
          width: g.spvw(212);
          margin-bottom: 10px;
        }

        p {
          cursor: pointer;
          display: block;
          font-size: 2.3rem;
          font-weight: 700;
          color: #4faae0;
          letter-spacing: 1px;
          text-align: center;
          padding: 15px 0;
          background: #cbe6f6;
          border-radius: 10px;
          border: 3px solid #cbe6f6;

          @include g.mq-down(md) {
            font-size: 1.15rem;
            padding: 6px 0;
          }
        }

        &.current {
          margin-bottom: 0;

          p {
            border-color: g.$blue;
            background: #fff;
            color: g.$blue;
            border-radius: 10px 10px 0 0;
            padding-bottom: 40px;
            position: relative;

            @include g.mq-down(md) {
              padding-bottom: 20px;
            }

            &::before {
              content: "";
              position: absolute;
              bottom: -3px;
              left: 0;
              width: 100%;
              height: 3px;
              background: #fff;
              z-index: 1;
            }
          }
        }
      }
    }

    .content {
      margin-top: -3px;

      .item {
        display: none;
        border: 3px solid g.$blue;
        background: #fff;
        padding: 40px;

        @include g.mq-down(md) {
          padding: 30px 12.5px;
        }

        &.current {
          display: block;
        }

        dl {
          margin-bottom: 35px;

          @include g.mq-down(md) {
            margin-bottom: 30px;
          }

          &:nth-last-of-type(1) {
            margin-bottom: 0;
          }

          dt {
            background: g.$main-grad();
            font-weight: 700;
            font-size: 1.8rem;
            letter-spacing: 2px;
            text-align: center;
            color: #fff;
            padding: 5px 0;
            border-radius: 9999px;
            margin-bottom: 35px;

            @include g.mq-down(md) {
              font-size: 1.4rem;
              margin-bottom: 20px;
            }
          }

          dd {
            font-size: 1.6rem;
            padding: 0 15px;

            @include g.mq-down(md) {
              font-size: 1.4rem;
              padding: 0 5px;
            }

            .job-box {
              display: flex;
              //flex-wrap: wrap;

              @include g.mq-down(md) {
                flex-wrap: wrap;
                justify-content: space-between;
              }

              .job {
                width: 157px;
                margin-right: g.pcminvw(12);
                text-align: center;

                @include g.mq-down(md) {
                  width: 47%;
                  margin-right: 0;
                  margin-bottom: 20px;
                }

                &:nth-last-of-type(1) {
                  margin-right: 0;
                }

                .caption {
                  margin-top: 5px;
                  color: g.$blue;
                  display: flex;
                  justify-content: center;

                  .count {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 20px;
                    height: 20px;
                    position: relative;
                    font-size: 1.4rem;
                    margin-top: 2px;

                    &::before {
                      content: "";
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                      width: 20px;
                      height: 20px;
                      border: 1px solid g.$blue;
                      border-radius: 50%;
                    }

                  }

                  .name {
                    margin-left: 5px;
                    line-height: 1.4;
                  }
                }
              }
            }

            .komidashi {
              color: g.$blue;
            }

            .flow {
              width: 573px;
              margin: 0 auto 25px;

              @include g.mq-down(md) {
                width: g.spvw(250);
              }
            }

            .read {
              text-align: center;
              color: g.$blue;
              font-weight: 700;
            }
          }
        }

        .not-entry {
          text-align: center;
          padding: 100px 0;
        }
      }
    }
  }

  .parallax {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    .parallax1 {
      width: 1700px;
      margin-top: 700px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 0;

      @include g.mq-down(md) {
        display: none;
        // width: g.spvw(1000);
        // margin-top: 300px;
        // z-index: -1;
      }
    }

    .parallax2 {
      width: 1600px;
      margin-top: 650px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 0;

      @include g.mq-down(md) {
        display: none;
        // width: g.spvw(843);
        // margin-top: 200px;
        // z-index: -1;
      }
    }
  }
}
