@use "../global" as g;

.p-fitness {

  .pagehead-sec {
    margin-bottom: 0px;
    position: relative;
    z-index: 1;

    @include g.mq-down(md) {
      margin-bottom: 0px;
    }

    .midashi {
      background: g.$purple;
    }
  }

  .mv-sec {
    margin-top: -30px;
    margin-bottom: 40px;
    position: relative;

    @include g.mq-down(md) {
      margin-top: 0;
      margin-bottom: 0;
    }

    .inner {
      position: relative;

      .box {
        width: 100%;
        max-width: 950px;
        //margin: 0 auto;

        @include g.mq-down(md) {
          margin-left: -12.5px;
          width: calc(100% + 25px);
          margin-bottom: 30px;
        }

        .mv1 {
          mask-image: url("../images/fitness/mask_mv-01.svg");
          width: 100%;
        }
      }
    }
  }

  .concept-sec {
    margin-bottom: 80px;
    position: relative;

    @include g.mq-down(md) {
      margin-bottom: 50px;
    }

    .inner {
      .copy {
        font-size: 4.2rem;
        font-weight: 700;
        letter-spacing: 16px;
        color: g.$blue;
        margin-bottom: 20px;
        text-align: center;

        @include g.mq-down(md) {
          font-size: 1.6rem;
          letter-spacing: 6px;
          margin-left: -12.5px;
          width: calc(100% + 25px);
        }
      }

      .read {
        font-size: 1.7rem;
        letter-spacing: 2px;
        line-height: 2.4;
        text-align: center;
        margin-bottom: 60px;

        @include g.mq-down(md) {
          font-size: 1.3rem;
          line-height: 1.8;
          margin-bottom: 30px;
        }

        span {
          color: g.$blue;
        }
      }
    }
  }

  .curves-sec {
    padding: 70px 0 100px;
    position: relative;

    @include g.mq-down(md) {
      padding: 50px 0;
    }

    .inner {
      position: relative;
      z-index: 1;

      .midashi {
        margin-bottom: 70px;
        color: g.$purple;
        border-color: g.$purple;

        @include g.mq-down(md) {
          margin-bottom: 40px;
          letter-spacing: 4px;
        }
      }

      .facility-list {
        @include g.facility(g.$purple);

        .item {
          border-bottom: 2px dotted g.$light-blue;
          word-wrap: break-word;
        }

        .deco {
          display: flex;
          justify-content: space-between;
          margin-bottom: 5px;
          position: relative;

          &:nth-last-of-type(1) {
            margin-bottom: 0;
          }

          &::before {
            content: "";
            display: block;
            position: absolute;
            top: 11px;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 1px;
            border-top: 2px dotted g.$purple;
          }

          span {
            background: #fff;
            padding-right: 10px;

            &:nth-last-of-type(1) {
              text-align: right;
              padding-right: 0;
              padding-left: 10px;
            }
          }
        }
      }
    }

    .parallax {
      .parallax1 {
        position: absolute;
        top: 100px;
        left: 50%;
        transform: translateX(-50%);
        width: 1680px;
        z-index: 0;

        @include g.mq-down(md) {
          display: none;
        }
      }

      .parallax2 {
        position: absolute;
        top: 200px;
        left: 50%;
        transform: translateX(-50%);
        width: 1366px;
        z-index: 0;

        @include g.mq-down(md) {
          display: none;
        }
      }
    }
  }

  .kidssmile-sec {
    padding: 70px 0 100px;
    @include g.dot-bg(g.$yellow, 0.2);
    position: relative;

    .inner {
      position: relative;
      z-index: 1;

      .midashi {
        margin-bottom: 70px;
        color: g.$dark-orange;
        border-color: g.$dark-orange;

        @include g.mq-down(md) {
          margin-bottom: 40px;
          letter-spacing: 4px;
        }
      }

      .facility-list {
        @include g.facility(g.$dark-orange);
      }
    }

    .parallax {
      .parallax1 {
        position: absolute;
        top: 500px;
        left: 50%;
        transform: translateX(-50%);
        width: 1480px;
        z-index: 0;

        @include g.mq-down(md) {
          display: none;
        }
      }

      .parallax2 {
        position: absolute;
        top: 100px;
        left: 50%;
        transform: translateX(-50%);
        width: 1560px;
        z-index: 0;

        @include g.mq-down(md) {
          display: none;
        }
      }
    }
  }

}
