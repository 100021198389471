@use "../global" as g;

.el_midashi {
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 18px;
  color: #fff;
  background: g.$main-grad();
  width: g.pcvw(558 * 1.3);
  max-width: 558px;
  text-align: center;
  padding: 15px 0;
  border-radius: 20px;
  margin: 0 auto;

  @include g.mq-down(md) {
    font-size: 1.8rem;
    padding: 10px 0;
    letter-spacing: 10px;
    width: g.spvw(558);
    padding: 10px 0;

  }
}

.el_midashi2 {
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 18px;
  color: g.$blue;
  background: #FFF;
  border: 4px solid g.$blue;
  width: g.pcvw(558 * 1.3);
  max-width: 558px;
  text-align: center;
  padding: 11px 0;
  border-radius: 20px;
  margin: 0 auto;

  @include g.mq-down(md) {
    font-size: 1.8rem;
    padding: 10px 0;
    letter-spacing: 10px;
    width: g.spvw(558);
    padding: 10px 0;

  }
}

.el_btn1 {
  font-weight: 700;
  font-size: 1.6rem;
  display: flex;
  align-items: center;

  @include g.mq-down(md) {
    font-size: 1.3rem;
  }

  .icon {
    width: 53px;
    height: 53px;
    background: #000;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    transition: border .2s, background .2s, color .2s;

    @include g.mq-down(md) {
      width: 30px;
      height: 30px;
    }

    &::after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 9px 0 9px 12px;
      border-color: transparent transparent transparent #fff;
      margin-left: 3px;
      transition: border-color .2s;

      @include g.mq-down(md) {
        border-width: 4.5px 0 4.5px 6px;
        margin-left: 1.5px;
      }

    }
  }

}

.menu-btn {
  width: 60px;
  height: 60px;
  padding: 20px 15px;
  background: g.$blue;
  border-radius: 50%;

  .menu-trigger,
  .menu-trigger span {
    display: inline-block;
    box-sizing: border-box;
    transition: all .2s;
  }

  .menu-trigger {
    width: 100%;
    height: 20px;
    position: relative;
  }

  .menu-trigger span {
    background-color: #fff;
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0;
  }

  //.menu-trigger.active span {
  //    background-color: #FFFFFF;
  //}
  .menu-trigger span:nth-of-type(1) {
    top: 0;
    transform: translateY(0) rotate(0);
  }

  .menu-trigger span:nth-of-type(2) {
    top: 9px;
  }

  .menu-trigger span:nth-of-type(3) {
    bottom: 0;
    transform: translateY(0) rotate(0);
  }

  /* Animation*/
  .menu-trigger.active span:nth-of-type(1) {
    top: 50%;
    transform: rotate(45deg);
  }

  .menu-trigger.active span:nth-of-type(2) {
    transform: scaleX(0);
  }

  .menu-trigger.active span:nth-of-type(3) {
    top: 50%;
    transform: rotate(-45deg);
  }
}

.pagetop-btn {

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1.3;
  color: #fff;
  width: 60px;
  height: 60px;
  background: g.$red;
  border-radius: 50%;
  border: 2px solid #fff;
}
