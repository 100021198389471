@use "../global" as g;

.mv-sec {
  .parallax {
    .parallax1 {
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      width: 1600px;
      z-index: -2;

      @include g.mq-down(md) {
        top: 40px;
        width: calc(100% + 175px);
      }
    }

    .parallax2 {
      position: absolute;
      top: 100px;
      left: 50%;
      transform: translateX(-50%);
      width: 1440px;
      z-index: -1;

      @include g.mq-down(md) {
        top: 180px;
        width: calc(100% + 10px);
      }
    }
  }
}
