@use "../global" as g;

.p-about {
  .about-sec {
    margin-bottom: 120px;
    position: relative;

    @include g.mq-down(md) {
      margin-bottom: 50px;
    }

    .inner {

      .box {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        position: relative;

        @include g.mq-down(md) {
          display: block;
        }

        .block {
          &:nth-of-type(1) {
            width: 49.52%;

            @include g.mq-down(md) {
              width: 100%;
            }
          }

          &:nth-of-type(2) {
            width: 49.74%;

            @include g.mq-down(md) {
              width: 100%;
            }
          }

          &:nth-of-type(3) {
            width: calc(100% - 200px);
            margin-top: 50px;

            @include g.mq-down(lg) {
              width: 100%;
              margin-top: 20px;
            }
          }

          .nakamidashi {
            font-size: min(g.pcvw(60), 4.2rem);
            font-weight: 700;
            letter-spacing: 15px;
            line-height: 1.8;

            @include g.mq-down(md) {
              letter-spacing: 8px;
              font-size: 2.1rem;
              text-align: center;
              line-height: 2;
              margin-bottom: 20px;
            }

            span {
              color: g.$blue;
              position: relative;

              // &::before {
              //   content: "";
              //   display: block;
              //   width: 6px;
              //   height: 6px;
              //   border-radius: 50%;
              //   background: g.$blue;
              //   position: absolute;
              //   left: calc(50% - 6px);
              //   top: -8px;
              //   transform: translateX(-50%);

              //   @include g.mq-down(md) {
              //     width: 4px;
              //     height: 4px;
              //     left: calc(50% - 4px);
              //     top: -4px;
              //   }
              // }
            }
          }

          .president {
            width: 100%;
            margin: 0;
            border-radius: 20px;

            img {
              border-radius: 20px;
            }
          }

          .read {
            font-size: 1.7rem;
            line-height: 2.4;
            margin-bottom: 40px;
            text-align: justify;

            @include g.mq-down(md) {
              font-size: 1.3rem;
              line-height: 1.8;
              margin-bottom: 10px;
            }
          }

          .signature {
            text-align: right;
            font-size: 1.7rem;
            line-height: 1.8;

            @include g.mq-down(md) {
              font-size: 1.3rem;
            }

          }

          .jukebox {
            @include g.mq-down(lg) {
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              margin-top: -80px;
            }

            @include g.mq-down(md) {
              margin-top: -30px;
            }

            figure {
              width: 225px;
              position: absolute;
              bottom: 0;
              right: -50px;

              @include g.mq-down(lg) {
                position: relative;
                right: 0;
              }

              @include g.mq-down(md) {
                width: 110px;
                margin: 0 auto;
              }

              .onpu1 {
                width: percentage(22/225);
                position: absolute;
                top: percentage(44/322);
                left: 0;
              }

              .onpu2 {
                width: percentage(22/225);
                position: absolute;
                top: 0px;
                left: percentage(73/225);
              }

              .onpu3 {
                width: percentage(26/225);
                position: absolute;
                top: percentage(44/322);
                left: percentage(152/225);
              }

              .onpu4 {
                width: percentage(43/225);
                position: absolute;
                top: percentage(92/322);
                right: 0;
              }

              figcaption {
                color: g.$blue;
                font-size: 1.1rem;
                text-align: center;
                margin-top: 7px;
                position: relative;
                left: -18px;

                @include g.mq-down(lg) {
                  position: absolute;
                  bottom: -40px;
                  left: 23px;
                  font-size: 1.2rem;
                }

                @include g.mq-down(md) {
                  position: relative;
                  font-size: 1.0rem;
                  left: -8px;
                  bottom: -5px;
                }

              }
            }

            .caption {
              width: g.pcvw(580);
              max-width: 580px;
              position: absolute;
              bottom: -20px;
              left: 0;
              font-size: 1.4rem;
              line-height: 1.8;
              letter-spacing: 1px;
              text-align: justify;

              @include g.mq-down(lg) {
                position: static;
                width: calc(100% - 225px);
                max-width: none;
                margin-bottom: 10px;
              }

              @include g.mq-down(md) {
                width: calc(100% - 130px);
                font-size: 1.0rem;
                line-height: 1.6;
              }
            }
          }
        }
      }
    }

    .parallax {
      .parallax1 {
        position: absolute;
        top: 90px;
        left: 50%;
        transform: translateX(-50%);
        width: 1600px;
        z-index: -2;

        @include g.mq-down(md) {
          top: 160px;
          width: calc(100% + 175px);
        }
      }

      .parallax2 {
        position: absolute;
        top: 180px;
        left: 50%;
        transform: translateX(-50%);
        width: 1440px;
        z-index: -1;

        @include g.mq-down(md) {
          top: 260px;
          width: calc(100% + 37.5px);
        }
      }
    }

  }

  .philosophy-sec {
    padding: 70px 0 90px;
    background-color: #FCD600;
    background-image:
      radial-gradient(rgba(#000, 0.02) 3.5px, transparent 3.75px),
      radial-gradient(rgba(#000, 0.02) 3.5px, transparent 3.75px);
    background-size: 20px 20px;
    background-position: 0 0, 10px 10px;
    position: relative;

    @include g.mq-down(md) {
      padding: 50px 0;
    }

    .inner {
      position: relative;
      z-index: 1;

      .midashi {
        margin-bottom: 40px;

        @include g.mq-down(md) {
          margin-bottom: 20px;
        }
      }

      .copy {
        color: g.$blue;
        font-size: 3.3rem;
        font-weight: 700;
        text-align: center;
        line-height: 2;
        letter-spacing: 16px;
        margin-bottom: 40px;

        @include g.mq-down(md) {
          font-size: 1.4rem;
          letter-spacing: 4px;
          margin-left: -12.5px;
          width: calc(100% + 25px);
        }
      }

      .chart {
        width: g.pcvw(846);
        max-width: 846px;
        margin: 0 auto;

        @include g.mq-down(md) {
          margin-left: -12.5px;
          width: calc(100% + 25px);
        }
      }
    }

    .parallax {
      .parallax1 {
        position: absolute;
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
        width: 1680px;
        z-index: 0;

        @include g.mq-down(md) {
          display: none;
        }
      }

      .parallax2 {
        position: absolute;
        top: 170px;
        left: 50%;
        transform: translateX(-50%);
        width: 1600px;
        z-index: 0;

        @include g.mq-down(md) {
          display: none;
        }
      }
    }

  }

  .company-sec {
    padding: 70px 0 100px;
    background-color: transparent;
    background-image:
      radial-gradient(rgba(g.$light-blue, 0.1) 3.5px, transparent 3.75px),
      radial-gradient(rgba(g.$light-blue, 0.1) 3.5px, transparent 3.75px);
    background-size: 20px 20px;
    background-position: 0 0, 10px 10px;
    position: relative;

    @include g.mq-down(md) {
      padding: 50px 0;
    }

    .inner {
      position: relative;
      z-index: 1;

      .midashi {
        margin-bottom: 70px;
        margin-top: 70px;

        @include g.mq-down(md) {
          margin-bottom: 30px;
        }

        &:nth-of-type(1) {
          margin-top: 0;
        }
      }

      .radius-table {
        width: 100%;
        border-collapse: separate;
        border-radius: 15px;
        border-spacing: 0;
        border: none;
        border: 3px solid g.$light-blue;
        font-size: 1.6rem;
        line-height: 1.8;
        margin-bottom: 110px;

        @include g.mq-down(md) {
          font-size: 1.2rem;
          border-width: 2px;
          margin-bottom: 70px;
        }

        &:nth-last-of-type(1) {
          margin-bottom: 0;
        }

        a {
          color: g.$blue;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }

      .radius-table tr>* {
        padding: 5px 10px;
        border: none;

      }

      .radius-table tr:first-child>*:first-child {
        border-radius: 10px 0 0 0;

        @include g.mq-down(md) {
          border-radius: 11px 0 0 0;
        }
      }

      .radius-table tr:first-child>*:last-child {
        border-radius: 0 10px 0 0;

        @include g.mq-down(md) {
          border-radius: 0 11px 0 0;
        }
      }

      .radius-table tr:last-child {

        td,
        th {
          border-bottom: none;

        }

      }

      .radius-table tr:last-child>*:first-child {
        border-radius: 0 0 0 10px;

        @include g.mq-down(md) {
          border-radius: 0 0 0 11px;
        }
      }

      .radius-table tr:last-child>*:last-child {
        border-radius: 0 0 10px 0;

        @include g.mq-down(md) {
          border-radius: 0 0 10px 0;
        }
      }

      .radius-table th {
        width: 35%;
        background: g.$light-blue;
        color: #fff;
        border-bottom: #fff dotted 2px;
        padding: 20px 25px;
        vertical-align: middle;

        @include g.mq-down(md) {
          padding: 10px 12.5px;
        }
      }

      .radius-table td {
        width: 65%;
        background: #FFF;
        border-bottom: g.$light-blue dotted 2px;
        padding: 20px 25px;
        vertical-align: middle;
        text-align: justify;

        @include g.mq-down(md) {
          padding: 10px 12.5px;
        }

        .officer {
          display: flex;

          dt {
            width: 160px;

            @include g.mq-down(md) {
              width: 100px;
            }
          }

          dd {
            width: calc(100% - 160px);

            @include g.mq-down(md) {
              width: calc(100% - 120px);
            }
          }
        }
      }
    }

    .parallax {
      .obj {
        position: absolute;
        top: -1000px;
        left: 50%;
        transform: translateX(-50%);
        width: 1506px;
        z-index: -1;

        @include g.mq-down(md) {
          width: 100%;
          top: 160px;
        }
      }

      .parallax1 {
        position: absolute;
        top: 620px;
        left: 50%;
        transform: translateX(-50%);
        width: 1486px;
        z-index: 0;

        @include g.mq-down(md) {
          top: 160px;
          width: calc(100% + 175px);
        }
      }

      .parallax2 {
        position: absolute;
        top: 350px;
        left: 50%;
        transform: translateX(-50%);
        width: 1400px;
        z-index: 0;

        @include g.mq-down(md) {
          top: 160px;
          width: calc(100% + 175px);
        }
      }
    }
  }

  .history-sec {
    padding: 70px 0 100px;
    position: relative;
    overflow: hidden;

    @include g.mq-down(md) {
      padding: 50px 0;
    }

    .inner {
      .midashi {
        margin-bottom: 90px;

        @include g.mq-down(md) {
          margin-bottom: 50px;
        }
      }

      .list {

        .item {
          padding-bottom: 45px;
          position: relative;

          &:nth-last-of-type(1) {
            padding-bottom: 0;
          }

          @include g.mq-down(md) {
            padding-bottom: 20px;
          }

          &::after {
            content: "";
            display: block;
            width: 2px;
            height: 100%;
            background: g.$blue;
            position: absolute;
            left: 220px + 3px;
            top: 0;
            z-index: -3;

            @include g.mq-down(md) {
              left: 20px + 3px;
            }
          }

          &:nth-of-type(1) {
            &::before {
              content: "";
              display: block;
              width: 4px;
              height: 150px;
              background: #fff;
              position: absolute;
              left: 220px + 2px;
              top: 0;
              z-index: -2;
              transform: scaleY(0);
              transition: transform .4s;
              transform-origin: top center;

              @include g.mq-down(md) {
                left: 20px + 2px;
              }
            }

          }

          &:nth-of-type(2) {
            .year {
              background: #1059A6;
              border-color: #1059A6;
            }
          }

          &:nth-of-type(3) {
            .year {
              background: #4880BB;
              border-color: #4880BB;
            }
          }

          &:nth-of-type(4) {
            .year {
              background: #598CC1;
              border-color: #598CC1;
            }
          }

          &:nth-of-type(5) {
            .year {
              background: #59A3C1;
              border-color: #59A3C1;
            }
          }

          &:nth-of-type(6) {
            .year {
              background: #59B6E8;
              border-color: #59B6E8;
            }
          }

          &:nth-of-type(7) {
            .year {
              background: #4FAAE0;
              border-color: #4FAAE0;
            }
          }

          .year {
            cursor: pointer;
            color: #FFF;
            background: #003B84;
            border: 3px solid #003B84;
            border-radius: 50px;
            font-size: 2.6rem;
            letter-spacing: 3px;
            font-weight: 500;
            padding: 12px 0;
            text-align: center;
            position: relative;
            transition: background .2s, color .2s;

            &:hover {
              @include g.mq-up(md) {
                background: #fff;
                color: g.$blue;

                &::after {
                  border-color: g.$blue;
                }
              }
            }

            @include g.mq-down(md) {
              border: 2px solid #003B84;
              padding: 8px 0;
              font-size: 2rem;
              letter-spacing: 2px;
            }

            &::after {
              content: "";
              display: block;
              position: absolute;
              top: calc(50% - 5px);
              right: 30px;
              transform: translateY(-50%);
              transform: rotate(135deg);
              width: 10px;
              height: 10px;
              border-top: 2px solid #fff;
              border-right: 2px solid #fff;

              transition: transform .2s, top .2s;

              @include g.mq-down(md) {
                right: 20px;
                width: 8px;
                height: 8px;
                top: calc(50% - 4px);
              }
            }
          }


          .topics {
            padding: 45px 0 0;
            display: none;

            @include g.mq-down(md) {
              padding: 20px 0 0;
            }

            dl {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding-bottom: 60px;

              @include g.mq-down(md) {
                display: block;
                padding-bottom: 20px;
              }

              &:nth-last-of-type(1) {
                padding-bottom: 40px;

                @include g.mq-down(md) {
                  padding-bottom: 20px;
                }
              }

              &:nth-of-type(even) {
                dd {
                  .read {
                    background-color: #FFEEA4;
                  }
                }
              }

              dt {
                width: 220px;
                font-size: 3.6rem;
                font-weight: 500;
                letter-spacing: 4px;
                color: g.$light-blue;

                @include g.mq-down(md) {
                  width: 100%;
                  text-align: center;
                  letter-spacing: 1px;
                  font-size: 2.6rem;
                  margin-bottom: 10px;
                }

              }

              dd {
                //width: calc(100% - g.pcvw(220));
                width: calc(100% - 280px);
                position: relative;

                @include g.mq-down(md) {
                  width: calc(100% - 70px);
                  margin-left: 60px;
                  margin-right: 10px;
                }

                &::before {
                  content: "";
                  display: block;
                  width: 8px;
                  height: 8px;
                  background: g.$yellow;
                  border: 2px solid g.$blue;
                  border-radius: 50%;
                  position: absolute;
                  left: -60px;
                  top: 50%;
                  transform: translateY(-50%);
                  z-index: 0;

                  @include g.mq-down(md) {
                    left: -40px;
                  }
                }

                &::after {
                  content: "";
                  display: block;
                  width: 16px;
                  height: 16px;
                  background: #fff;
                  border: 2px solid g.$blue;
                  border-radius: 50%;
                  position: absolute;
                  left: -64px;
                  top: 50%;
                  transform: translateY(-50%);
                  z-index: -1;

                  @include g.mq-down(md) {
                    left: -44px;
                  }
                }

                .read {
                  padding: 15px;
                  width: 100%;
                  line-height: 2;
                  background: g.$yellow;
                  border-radius: 15px;

                  @include g.mq-down(md) {
                    font-size: 1.25rem;
                    padding: 12px 10px;
                    border-radius: 10px;
                    line-height: 1.8;
                  }

                  &::before {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 0px;
                    border-bottom: 3px dotted g.$light-blue;
                    position: absolute;
                    top: 50%;
                    left: -60px;
                    transform: translateY(-50%);
                    z-index: -2;

                    @include g.mq-down(md) {
                      left: -40px;
                    }
                  }
                }
              }
            }

            .close {
              width: 160px;
              display: inline-block;
              cursor: pointer;
              background: g.$red;
              border: 2px solid g.$red;
              color: #fff;
              padding: 10px 20px;
              margin-left: min(g.pcvw(280), 280px);
              border-radius: 50px;
              text-align: center;
              position: relative;
              transition: color .2s, background .2s;

              @include g.mq-down(md) {
                margin-left: 60px;
                margin-bottom: 15px;
              }

              &::after {
                content: "";
                display: block;
                position: absolute;
                top: calc(50% - 0px);
                right: 20px;
                transform: translateY(-50%);
                width: 6px;
                height: 6px;
                border-top: 1px solid #fff;
                border-right: 1px solid #fff;

                transform: rotate(-45deg);
              }

              &:hover {
                @include g.mq-up(md) {
                  color: g.$red;
                  background: #fff;

                  &::after {
                    border-top-color: g.$red;
                    border-right-color: g.$red;
                  }
                }

              }
            }
          }

          //active時
          &.is_active {
            &:nth-of-type(1) {
              &::before {
                transform: scaleY(1);
              }
            }

            .year {
              &::after {
                transform: rotate(-45deg);
                top: calc(50% + 0px);

                @include g.mq-down(md) {
                  top: calc(50% - 2px);
                }
              }
            }
          }
        }
      }
    }

    .parallax {
      .obj {
        position: absolute;
        top: 0px;
        left: 50%;
        transform: translateX(-50%);
        width: 1506px;
        height: 100%;
        z-index: -1;
        background-image: url(../images/about/parallax_company-03_pc.svg);
        background-position: center top;
        background-repeat: repeat-y;
        background-size: 1506px;

        @include g.mq-down(md) {
          width: 100%;
          top: 160px;
        }
      }

      .parallax1 {
        position: absolute;
        top: 620px;
        left: 50%;
        transform: translateX(-50%);
        width: 1486px;
        z-index: 0;

        @include g.mq-down(md) {
          top: 160px;
          width: calc(100% + 175px);
        }
      }

      .parallax2 {
        position: absolute;
        top: 350px;
        left: 50%;
        transform: translateX(-50%);
        width: 1400px;
        z-index: 0;

        @include g.mq-down(md) {
          top: 160px;
          width: calc(100% + 175px);
        }
      }
    }
  }

  .bg {
    background-image: url(../images/about/bg-01_pc.svg);
    background-position: center top -800px;
    background-repeat: repeat-y;
    background-size: 1560px;
    position: relative;
    padding-bottom: 180px;

    @include g.mq-down(md) {
      padding-bottom: 0px;
      background-image: url(../images/common/bg-01_sp.svg);
      background-size: 100%;
    }
  }
}
