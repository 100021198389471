@use "../global" as g;

footer {
  background: #fff;
  padding: 95px 0px;

  .p-404 &,
  .p-contact &,
  .p-news & {
    background: transparent;
  }

  @include g.mq-down(md) {
    padding: 30px 0 100px;
  }

  .inner {
    position: relative;

    .logo {
      width: 180px;
      margin: 0 auto 60px;

      @include g.mq-down(md) {
        width: 90px;
        margin: 0 auto 45px;
      }
    }

    .box {
      display: flex;
      justify-content: space-between;

      @include g.mq-down(md) {
        display: block;
      }

      .nav {
        width: percentage(595px / 950px);
        display: flex;
        justify-content: space-between;
        padding-right: percentage(50px / 950px);
        position: relative;

        @include g.mq-down(md) {
          display: block;
          width: 100%;
          padding-right: 0;
        }

        &::after {
          content: "";
          width: 1px;
          height: 100%;
          background: g.$blue;
          position: absolute;
          top: 0;
          right: 0;

          @include g.mq-down(md) {
            content: none;
          }
        }

        .col {
          width: 35%;

          @include g.mq-down(md) {
            width: 100%;
          }

          &>ul {
            @include g.mq-down(md) {
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
            }

            li {
              margin-bottom: 32px;
              font-size: 1.7rem;

              @include g.mq-down(md) {
                width: 50%;
                font-size: 1.4rem;

                &:nth-of-type(2) {
                  width: 40%;
                }
              }

              &:nth-last-of-type(1) {
                @include g.mq-up(md) {
                  margin-bottom: 0;
                }
              }

              a {
                font-weight: 700;
                color: g.$blue;
                text-decoration: underline;

                &:hover {
                  text-decoration: none;
                }
              }
            }
          }

          p {
            font-weight: 700;
            color: g.$blue;
            font-size: 1.7rem;
            margin-bottom: 4px;

            @include g.mq-down(md) {
              font-size: 1.4rem;
            }
          }



          &:nth-child(2) {
            width: 65%;

            @include g.mq-down(md) {
              width: 100%;
            }

            &>.box {
              @include g.mq-down(md) {
                display: flex;
                justify-content: space-between;

                @include g.mq-down(md) {
                  padding-bottom: 30px;
                  border-bottom: 1px solid g.$blue;
                }
              }
            }

            ul {
              width: 60%;

              &:nth-of-type(2) {
                width: 40%;
              }

              li {
                font-size: 1.5rem;
                margin-top: 14px;
                margin-bottom: 0;

                @include g.mq-down(md) {
                  font-size: 1.4rem;
                }

                a {
                  color: g.$light-blue;
                  text-decoration: underline;

                  span {
                    display: inline-flex;
                    vertical-align: middle;
                    transform: translateY(-1px);
                    margin-left: 3px;

                    svg {
                      width: 15px;
                      height: 15px;
                      fill: g.$light-blue;
                    }

                  }

                  &:hover {
                    text-decoration: none;
                  }
                }

              }
            }
          }
        }
      }

      .address {
        padding-left: percentage(50px / 950px);
        width: calc(100% - percentage(595px / 950px));
        font-size: 1.5rem;
        color: g.$blue;

        @include g.mq-down(md) {
          font-size: 1.4rem;
          width: 100%;
          padding-left: 0;
          margin-top: 30px;
        }

        .btn {
          display: block;
          width: 100%;
          letter-spacing: 2px;
          background: g.$blue;
          border: 2px solid g.$blue;
          border-radius: 9999px;
          line-height: 1;
          color: #fff;
          margin-top: 25px;
          padding: 15px 20px;
          position: relative;
          text-align: center;
          transition: color 0.2s, background 0.2s;

          &::after {
            border-right: 1px solid #fff;
            border-top: 1px solid #fff;
            content: "";
            display: block;
            width: 6px;
            height: 6px;
            position: absolute;
            right: 20px;
            top: calc(50% - 3px);
            transform: rotate(45deg);
            transition: border-color .2s;
          }

          &:hover {
            background: #fff;
            color: g.$blue;

            &::after {
              border-color: g.$blue;
            }

          }
        }
      }
    }

    .copyright {
      color: g.$blue;
      font-weight: 700;
      text-align: center;
      margin-top: 70px;

      @include g.mq-down(md) {
        margin-top: 40px;
      }
    }
  }
}

.pagetop-btn {
  position: fixed;
  bottom: 15px;
  left: 20px;
  z-index: 210;
  box-shadow: 3px 2px 4px rgba(#000, .4);
}
