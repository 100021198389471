@use "../global" as g;

.p-privacy {
  .pagehead-sec {
    margin-bottom: 80px;

    @include g.mq-down(md) {
      margin-bottom: 40px;
    }
  }

  .content-sec {
    position: relative;
    margin-bottom: 100px;
    text-align: justify;

    @include g.mq-down(md) {
      margin-bottom: 50px;
    }

    .intro {
      font-size: 1.7rem;
      line-height: 2.2;
      margin-bottom: 50px;

      @include g.mq-down(md) {
        font-size: 1.3rem;
        margin-bottom: 30px;
        line-height: 2;
      }
    }

    .articles {
      font-size: 1.7rem;
      margin-bottom: 50px;
      text-align: justify;

      @include g.mq-down(md) {
        font-size: 1.3rem;
        margin-bottom: 30px;
      }

      .article {
        display: flex;
        align-items: first baseline;
        margin-bottom: 20px;

        &:nth-last-of-type(1) {
          margin-bottom: 0;
        }

        .num {
          width: 2.5rem;
          color: g.$blue;

          @include g.mq-down(md) {
            width: 2.2rem;
          }
        }

        .text {
          width: calc(100% - 2.5rem);

          @include g.mq-down(md) {
            width: calc(100% - 2.2rem);
          }
        }
      }
    }

    .contact {
      font-size: 1.7rem;
      line-height: 2;
      margin-bottom: 30px;

      @include g.mq-down(md) {
        font-size: 1.3rem;
        line-height: 1.8;
      }

      .midashi {
        color: g.$blue;
      }
    }

    .signature {
      font-size: 1.7rem;
      line-height: 2;
      text-align: right;

      @include g.mq-down(md) {
        font-size: 1.3rem;
        line-height: 1.8;
      }
    }

    .parallax {

      .parallax1 {
        width: 1600px;
        position: absolute;
        top: 0px;
        left: 50%;
        transform: translateX(-50%);
        z-index: -2;

        @include g.mq-down(md) {
          top: 70px;
          z-index: -3;
          width: calc(g.spvw(750) + 175px);
        }
      }

      .parallax2 {
        width: 1480px;
        position: absolute;
        top: 70px;
        left: 50%;
        transform: translateX(-50%);
        z-index: -3;

        @include g.mq-down(md) {
          z-index: -2;
          width: calc(g.spvw(750) + 45px);
          top: 140px;
        }
      }
    }
  }

  footer {
    background: transparent;
  }
}
