@use "../global" as g;

header {
  width: 100%;
  position: relative;
  z-index: 100;
  background-image: url(../images/common/deco_header_pc.svg);
  background-position: top -125px center;
  background-repeat: no-repeat;
  background-size: 1691px;
  height: 234px;

  @include g.mq-down(md) {
    background-image: url(../images/common/deco_header_sp.svg);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 117px;
  }

  .inner {
    padding: 100px 0 0;
    position: relative;

    @include g.mq-down(md) {
      padding: 50px 0 0;
    }

    .logo {
      width: 140px;
      margin: 0 auto 20px;

      @include g.mq-down(md) {
        width: 75px;
      }
    }

    .menu {
      .menu-btn {
        @include g.mq-up(md) {
          display: none !important;
        }

        position: fixed;
        bottom: 15px;
        right:20px;
        z-index: 210;
        box-shadow: 3px 2px 4px rgba(#000, .4);
      }

      nav {
        @include g.mq-up(md) {
          opacity: 1 !important;
          visibility: visible !important;
        }

        @include g.mq-down(md) {
          position: fixed;
          top: 0;
          left: 0;
          z-index: 200;
          width: 100%;
          height: 100vh;
          background: rgba(#FFF, 0.8);
          opacity: 0;
          visibility: hidden;
        }

        ul {
          display: flex;
          justify-content: center;

          @include g.mq-down(md) {
            text-align: right;
            display: block;
            position: fixed;
            bottom: 120px;
            right: 0px;
          }

          li {
            padding: 0 22px;

            @include g.mq-down(md) {
              padding: 0 20px;
              margin-top: 15px;
            }

            a {
              color: g.$blue;
              font-weight: 700;

              @include g.mq-down(md) {
                font-size: 1.5rem;
              }
            }

            &.drop-down {
              position: relative;

              @include g.mq-up(md) {
                padding-right: 0;
              }


              &>a {
                position: relative;
                padding-right: 22px;

                @include g.mq-down(md) {
                  padding-right: 0;
                  display: block;
                  pointer-events: none;
                  font-size: 1.2rem;
                  margin-bottom: 10px;
                }

                &::after {
                  content: "";
                  position: absolute;
                  right: 6px;
                  top: 6px;
                  width: 6px;
                  height: 6px;
                  border-top: 1.5px solid g.$blue;
                  border-right: 1.5px solid g.$blue;
                  transform: rotate(135deg);

                  @include g.mq-down(md) {
                    display: none;
                  }
                }
              }

              .child-menu {
                display: none;
                position: absolute;
                top: 40px;
                left: 0;
                width: 200%;
                padding: 10px;
                background: rgba(g.$yellow, 0.8);
                border-radius: 10px;
                z-index: 10;

                @include g.mq-down(md) {
                  display: block !important;
                  position: static;
                  background: transparent;
                  border: 2px dotted g.$blue;
                  border-right: 0;
                  border-left: 0;
                  border-radius: 0;
                  width: calc(g.spvw(750) - 50px);
                  margin-bottom: 10px;
                  padding: 5px 0;

                }

                a {
                  display: block;
                  font-size: 1.3rem;
                  font-weight: 500;
                  margin-bottom: 10px;

                  @include g.mq-down(md) {
                    font-size: inherit;
                    font-weight: 700;
                    margin: 0px;
                    padding: 5px 0;
                  }

                  .outlink {
                    display: inline-block;
                    width: 18px;
                    height: 14px;
                    margin-left: 2px;
                    vertical-align: middle;
                    position: relative;
                    top: -1px;

                    @include g.mq-down(md) {
                      width: 16px;
                      height: 14px;
                    }

                    svg {
                      width: 100%;
                      height: 100%;
                      vertical-align: top;
                      fill: g.$blue;
                    }
                  }
                }
              }
            }

          }
        }
      }
    }

    .inquiry {
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 100px;
        background: #FFF;
        border: 4px solid #000;
        border-top: none;
        border-radius: 0 0 20px 20px;
        text-align: center;
        font-weight: 700;
        position: absolute;
        top: 0;
        right: calc(50% - 520px);
        transition: height .2s;

        @include g.mq-down(md) {
          position: fixed;
          top: 0;
          right: 0;
          z-index: 100;
          border: 3px solid #000;
          border-top: none;
          width: 100px;
          height: 50px;
          border-radius: 0 0 10px 10px;
          right: 12px;
          font-size: 1.2rem;

          span {
            padding: 0 0 3px;
          }
        }

        @include g.mq-up(md) {
          &:hover {
            height: 110px;
          }
        }

      }
    }
  }
}
