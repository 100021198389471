@use "../global" as g;

.p-top {
  .mv-sec {
    //margin-top: -30px;
    margin-bottom: 30px;
    position: relative;

    @include g.mq-down(md) {
      margin-top: 0;
    }

    .inner {
      position: relative;
      z-index: 1;

      @include g.mq-down(md) {
        width: calc(100% - 20px);
      }

      .copy {
        position: relative;

        p {
          display: inline-block;
          font-size: min(g.pcvw(31 * 1.3), 3.1rem);
          font-weight: 700;
          color: #fff;
          letter-spacing: min(g.pcvw(16 * 1.3), 16px);
          padding: min(g.pcvw(10 * 1.3), 10px) min(g.pcvw(20 * 1.3), 20px);
          //padding: 10px 20px;
          text-align: right;
          position: absolute;
          right: min(g.pcvw(70 * 1.3), 70px);
          top: 0;
          z-index: 11;

          @include g.mq-down(md) {
            font-size: 1.5rem;
            right: 0px;
            letter-spacing: 10px;
            padding: 7px 14px;
          }

          &:nth-of-type(1) {
            background: g.$sub-grad;
            top: min(g.pcvw(30 * 1.3), 30px);

            @include g.mq-down(md) {
              top: 0px;
            }
          }

          &:nth-of-type(2) {
            background: g.$main-grad;
            top: min(g.pcvw(120 * 1.3), 120px);

            @include g.mq-down(md) {
              top: 45px;
            }
          }
        }
      }

      .mv {
        position: relative;
        z-index: 1;
        width: g.pcvw(904 * 1.3);
        max-width: 904px;
        height: g.pcvw(638 * 1.3);
        max-height: 638px;
        margin: 0 auto;
        //overflow: hidden;

        @include g.mq-down(md) {
          width: 100%;
          height: auto;
        }

        .slides-wrap {
          mask-image: url("../images/top/slide_mask.svg");

          .slide {
            figure {
              margin: 0;
            }
          }
        }

        .slick-dots {
          text-align: left;
          bottom: 90px;
          left: -40px;

          @include g.mq-down(md) {
            text-align: center;
            bottom: 0px;
            left: 12vw;
          }
        }
      }

      // .mv-control {
      //   display: flex;
      //   position: absolute;
      //   bottom: 90px;
      //   left: max(g.pcvw(20), 20px);
      //   z-index: 5;

      //   @include g.mq-down(md) {
      //     left: calc(50% + 50px);
      //     transform: translateX(-50%);
      //     bottom: 0;
      //   }

      //   li {
      //     cursor: pointer;
      //     width: g.pcvw(12);
      //     max-width: 12px;
      //     height: g.pcvw(12);
      //     max-height: 12px;
      //     border-radius: 50%;
      //     margin-right: 7px;
      //     background: #5aa6e0;

      //     @include g.mq-down(md) {
      //       width: g.pcvw(24);
      //       max-width: 24px;
      //       height: g.pcvw(24);
      //       max-height: 24px;
      //     }

      //     &.current {
      //       background: g.$blue;
      //     }
      //   }
      // }

      .object {
        .obj1 {
          $obj1-w: 180;
          $obj1-h: 189;
          position: absolute;
          z-index: 2;
          top: min(g.pcvw(-20), -20px);
          left: min(g.pcvw(90), 90px);
          max-width: 1px * $obj1-w;
          width: g.pcvw($obj1-w * 1.3);
          max-height: 1px * $obj1-h;
          height: g.pcvw($obj1-h * 1.3);

          @include g.mq-down(md) {
            top: min(g.pcvw(0), 0px);
          }
        }

        .obj2 {
          $obj2-w: 180;
          $obj2-h: 189;
          position: absolute;
          z-index: 2;
          top: min(g.pcvw(150), 150px);
          left: min(g.pcvw(-70), -70px);
          max-width: 1px * $obj2-w;
          width: g.pcvw($obj2-w * 1.3);
          max-height: 1px * $obj2-h;
          height: g.pcvw($obj2-h * 1.3);

          @include g.mq-down(md) {
            top: calc(50% - g.pcvw($obj2-h * 1.3) / 2);
            left: min(g.pcvw(-10), -10px);
          }
        }

        .obj3 {
          $obj3-w: 180;
          $obj3-h: 189;
          position: absolute;
          z-index: 2;
          top: min(g.pcvw(350), 350px);
          left: min(g.pcvw(0), 0px);
          max-width: 1px * $obj3-w;
          width: g.pcvw($obj3-w * 1.3);
          max-height: 1px * $obj3-h;
          height: g.pcvw($obj3-h * 1.3);

          @include g.mq-down(md) {
            top: auto;
            bottom: min(g.pcvw(-10), -10px);
            left: min(g.pcvw(70), 70px);
          }
        }

        .obj4 {
          $obj4-w: 180;
          $obj4-h: 189;
          position: absolute;
          z-index: 2;
          top: min(g.pcvw(315), 315px);
          right: min(g.pcvw(-25), -25px);
          max-width: 1px * $obj4-w;
          width: g.pcvw($obj4-w * 1.3);
          max-height: 1px * $obj4-h;
          height: g.pcvw($obj4-h * 1.3);

          @include g.mq-down(md) {
            top: auto;
            bottom: min(g.pcvw(-10), -10px);
            right: 10px;
          }
        }
      }
    }

    .parallax {

      .parallax1 {
        width: 1600px;
        position: absolute;
        top: 120px;
        left: 50%;
        transform: translateX(-50%);
        z-index: -2;

        @include g.mq-down(md) {
          width: g.spvw(750);
        }
      }

      .parallax2 {
        width: 1440px;
        position: absolute;
        top: 300px;
        left: 50%;
        transform: translateX(-50%);
        z-index: -3;

        @include g.mq-down(md) {
          width: g.spvw(750);
          top: calc(50% + 40px);
        }
      }
    }
  }

  .concept-sec {
    margin-bottom: 100px;

    @include g.mq-down(md) {
      margin-bottom: 50px;
    }

    .inner {
      text-align: center;
      margin-bottom: 80px;

      @include g.mq-down(md) {
        margin-bottom: 40px;
      }

      .midashi {
        font-size: 2rem;
        font-weight: 700;
        color: g.$blue;
        margin-bottom: 5px;

        @include g.mq-down(md) {
          font-size: 1.4rem;
        }
      }

      .copy {
        font-size: 4.2rem;
        font-weight: 700;
        letter-spacing: 16px;
        color: g.$blue;
        margin-bottom: 20px;

        @include g.mq-down(md) {
          font-size: 2.1rem;
          letter-spacing: 9px;
        }
      }

      .read {
        font-size: 1.7rem;
        letter-spacing: 2px;
        line-height: 2.4;

        @include g.mq-down(md) {
          font-size: 1.3rem;
          line-height: 1.8;
        }

        span {
          color: g.$blue;
        }
      }
    }

    .imgs {
      position: relative;

      .box {
        display: flex;
        justify-content: center;


        .img1 {
          mask-image: url("../images/top/mask.svg");
          $img1-w : 410;
          $img1-h : 309;
          width: g.pcvw($img1-w * 1.3);
          max-width: 1px * $img1-w;
          height: g.pcvw($img1-h * 1.3);
          max-height: 1px * $img1-h;

          @include g.mq-down(md) {
            width: g.spvw($img1-w * 0.95);
            height: g.spvw($img1-h * 0.95);
          }
        }

        .img2 {
          mask-image: url("../images/top/mask2.svg");
          $img2-w : 361;
          $img2-h : 272;
          width: g.pcvw($img2-w * 1.3);
          max-width: 1px * $img2-w;
          height: g.pcvw($img2-h * 1.3);
          max-height: 1px * $img2-h;
          margin-top: min(g.pcvw(70), 70px);
          margin-left: -70px;

          @include g.mq-down(md) {
            width: g.spvw($img2-w * 0.95);
            height: g.spvw($img2-h * 0.95);
            margin-left: -40px;
          }
        }
      }

      .parallax {
        .obj {
          max-width: 1467px;
          width: g.pcvw(1467 * 1.3);
          margin: 0 auto;
          position: absolute;
          top: calc(50% + 10px);
          left: 50%;
          transform: translate(-50%, -50%);

          @include g.mq-down(md) {
            width: g.spvw(750);
            top: calc(50% + 50px);
            z-index: -1;
          }
        }

        .parallax1 {
          max-width: 1366px;
          width: g.pcvw(1366 * 1.3);
          margin: 0 auto;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          @include g.mq-down(md) {
            width: g.spvw(750);
            top: calc(50% + 75px);
            z-index: -1;
          }
        }

        .parallax2 {
          max-width: 343px;
          width: g.pcvw(343 * 1.3);
          margin: 0 auto;
          position: absolute;
          top: calc(50% + 200px);
          left: calc(50% + 405px);
          transform: translateY(-50%);

          @include g.mq-down(md) {
            display: none;
          }

        }

        .parallax3 {
          max-width: 282px;
          width: g.pcvw(282 * 1.3);
          margin: 0 auto;
          position: absolute;
          top: calc(50% + 250px);
          left: calc(50% - 560px);
          transform: translateY(-50%);
          z-index: -1;

          @include g.mq-down(md) {
            width: g.spvw(196);
            top: calc(50% + 5px);
            left: auto;
            right: -20px;
            z-index: -1;
          }
        }
      }

    }
  }

  .business-sec {
    background-image: url(../images/common/bg.svg);
    background-position: center top;
    background-repeat: repeat-y;
    background-size: 1506px;
    position: relative;
    padding-bottom: 180px;

    @include g.mq-down(md) {
      padding-bottom: 0px;
      background-image: url(../images/common/bg_sp.svg);
      background-size: 100%;
    }

    .inner {
      .midashi {
        margin-bottom: 30px;

        @include g.mq-down(md) {
          margin-bottom: 20px;
        }
      }

      .outline {
        font-size: 2.5rem;
        text-align: center;
        letter-spacing: 2px;

        @include g.mq-down(md) {
          font-size: 1.3rem;
          margin-bottom: 20px;
        }
      }

      .list {
        margin: 0 calc(135px / 2);
        position: relative;
        z-index: 2;

        @include g.mq-down(md) {
          margin: 0;
        }

        .item {
          display: flex;
          align-items: center;
          margin-bottom: 0px;

          @include g.mq-down(lg) {
            display: block;
            margin-bottom: 50px;
          }

          &:nth-child(even) {
            flex-direction: row-reverse;

            .text {
              margin-left: 0;

              @include g.mq-up(lg) {
                margin-top: 0px;
                margin-right: -45px;
              }

            }

            .photo {
              margin-top: 150px;

              @include g.mq-down(lg) {
                margin-top: 0px;
                margin-left: calc(100% - g.spvw(564));
              }
            }
          }

          .photo {
            width: calc(50% + 45px);
            max-height: 351px;
            height: g.pcvw(351);
            position: relative;
            z-index: 0;

            @include g.mq-down(lg) {
              width: g.spvw(564);
              height: g.spvw(383);
            }

            figure {
              border-radius: 20px;
              @include g.imgFit();

              img {
                border-radius: 20px;
              }
            }
          }

          .text {
            width: 50%;
            min-height: 363px;
            padding: 35px 45px 140px;
            margin-left: -45px;
            margin-top: 150px;
            background: #fff;
            border-radius: 20px;
            box-shadow: 5px 5px 5px rgba(#000000, 0.3);
            position: relative;
            z-index: 1;
            position: relative;

            @include g.mq-down(lg) {
              min-height: auto;
              margin-top: -35px;
              width: g.spvw(550);
              margin-left: calc(100% - g.spvw(550));
              padding: 35px 45px;
            }

            @include g.mq-down(md) {
              padding: 15px 20px;
            }

            .komidashi {
              font-size: 2.2rem;
              font-weight: 700;
              margin-bottom: 10px;

              @include g.mq-down(lg) {
                font-size: 1.3rem;
                margin-bottom: 6px;
              }
            }

            .copy {
              font-size: 2.3rem;
              font-weight: 700;
              letter-spacing: 1px;
              margin-bottom: 20px;

              @include g.mq-down(md) {
                font-size: 1.4rem;
                margin-bottom: 10px;
              }
            }

            .read {
              line-height: 2;

              @include g.mq-down(lg) {
                margin-bottom: 30px;
              }

              @include g.mq-down(md) {
                font-size: 1.2rem;
                line-height: 1.8;
                margin-bottom: 15px;
              }
            }

            .btn {
              position: absolute;
              bottom: 35px;
              left: 45px;

              @include g.mq-down(lg) {
                position: static;
              }

              .outlink {
                display: inline-block;
                width: 20px;
                height: 16px;
                margin-left: 5px;

                @include g.mq-down(md) {
                  width: 16px;
                  height: 14px;
                }

                svg {
                  width: 100%;
                  height: 100%;
                  vertical-align: top;
                }
              }
            }

          }

          //色
          &:nth-of-type(1) {
            $item-color1: g.$blue;

            .text {
              .komidashi {
                color: $item-color1
              }

              .btn {
                .icon {
                  background-color: $item-color1;
                  border: 2px solid $item-color1;
                }

                &:hover {
                  color: $item-color1;

                  .icon {
                    background-color: #fff;

                    &::after {
                      border-color: transparent transparent transparent $item-color1;
                    }
                  }
                }
              }
            }
          }

          &:nth-of-type(2) {
            $item-color2: g.$dark-orange;

            .text {
              .komidashi {
                color: $item-color2;
              }

              .btn {
                .icon {
                  background-color: $item-color2;
                  border: 2px solid $item-color2;

                }

                &:hover {
                  color: $item-color2;

                  .icon {
                    background-color: #fff;

                    &::after {
                      border-color: transparent transparent transparent $item-color2;
                    }

                  }

                }
              }
            }
          }


          // &:nth-of-type(3) {
          //   $item-color3: g.$orange;

          //   .text {
          //     .komidashi {
          //       color: $item-color3;
          //     }

          //     .btn {
          //       .icon {
          //         background-color: $item-color3;
          //         border: 2px solid $item-color3;
          //       }

          //       &:hover {
          //         color: $item-color3;


          //         .icon {
          //           background-color: #fff;

          //           &::after {
          //             border-color: transparent transparent transparent $item-color3;
          //           }
          //         }
          //       }
          //     }
          //   }
          // }

          &:nth-of-type(3) {
            $item-color4: g.$green;

            .text {
              .komidashi {
                color: $item-color4
              }

              .btn {
                .icon {
                  background-color: $item-color4;
                  border: 2px solid $item-color4;
                }

                &:hover {
                  color: $item-color4;


                  .icon {
                    background-color: #fff;

                    &::after {
                      border-color: transparent transparent transparent $item-color4;
                    }
                  }
                }
              }
            }
          }

          &:nth-of-type(4) {
            $item-color5: g.$pink;

            .text {
              .komidashi {
                color: $item-color5
              }

              .btn {
                .icon {
                  background-color: $item-color5;
                  border: 2px solid $item-color5;
                }

                &:hover {
                  color: $item-color5;

                  .icon {
                    background-color: #fff;

                    &::after {
                      border-color: transparent transparent transparent $item-color5;
                    }
                  }
                }
              }
            }
          }

          &:nth-of-type(5) {
            $item-color6: g.$purple;

            .text {
              .komidashi {
                color: $item-color6
              }

              .btn {
                .icon {
                  background-color: $item-color6;
                  border: 2px solid $item-color6;
                }

                &:hover {
                  color: $item-color6;

                  .icon {
                    background-color: #fff;

                    &::after {
                      border-color: transparent transparent transparent $item-color6;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .parallax {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);

      .parallax1 {
        width: 1700px;
        margin-top: 700px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 0;

        @include g.mq-down(md) {
          width: g.spvw(1000);
          margin-top: 300px;
          z-index: -1;
        }
      }

      .parallax2 {
        width: 1366px;
        margin-top: 650px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 0;

        @include g.mq-down(md) {
          width: g.spvw(843);
          margin-top: 200px;
          z-index: -1;
        }
      }
    }
  }

  .news-sec {
    margin-bottom: 60px;

    .inner {
      .midashi {
        margin-bottom: 40px;
      }

      .list {
        .item {
          display: block;
          border-bottom: 1px solid #CCC;
          padding: 20px;
          transition: background-color .2s;

          @include g.mq-down(md) {
            padding: 10px 0px;
          }

          &:hover {
            @include g.mq-up(md) {
              background-color: rgba(g.$blue, $alpha: 0.05);
            }

          }

          &:last-of-type {
            @include g.mq-up(md) {
              border-bottom: none;
            }
          }

          dl {
            display: flex;
            align-items: start;
            font-size: 1.6rem;
            color: g.$blue;
            position: relative;

            @include g.mq-down(md) {
              justify-content: space-between;
              flex-wrap: wrap;
              font-size: 1.3rem;
            }

            dt {
              padding: 6px 0;
              margin-right: 15px;

              @include g.mq-down(md) {
                padding: 1px 0;
              }
            }

            dd {
              display: flex;
              align-items: start;

              &:nth-of-type(1) {
                margin-right: 15px;

                @include g.mq-down(md) {
                  margin-right: 0;
                }
              }

              &:nth-of-type(2) {
                @include g.mq-down(md) {
                  width: 100%;
                  margin-top: 10px;
                }
              }

              .cat {
                background: g.$yellow;
                padding: 6px 0;
                width: 200px;
                text-align: center;
                border-radius: 50px;

                @include g.mq-down(md) {
                  width: 100px;
                  padding: 1px 0;
                }
              }

              .title {
                font-size: 1.8rem;
                line-height: 2;
                padding: 0;
                font-weight: 500;
                margin-right: 15px;

                @include g.mq-down(md) {
                  font-size: 1.3rem;
                  line-height: 1.6;
                  font-weight: 700;
                }
              }

              .badge {
                border: 2px solid g.$red;
                border-radius: 50px;
                padding: 2px 20px;
                line-height: 1.8;
                color: g.$red;

                @include g.mq-down(md) {
                  padding: 0px 10px;
                  line-height: 1.3;
                }

              }
            }
          }
        }
      }

      .more {
        text-align: right;
        margin-top: 20px;

        @include g.mq-down(md) {
          text-align: center;
        }

        a {
          font-size: 1.7rem;
          color: g.$blue;
          text-decoration: underline;

          @include g.mq-down(md) {
            font-size: 1.3rem;
            font-weight: 700;
          }

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}
