@use "../setting" as s;
@use "../breakpoint" as b;

// アンダーラインアニメーション
@mixin hover-underline($type: fade, $dir: null, $weight: 2px, $color: #000) {
  @if $dir==null {
    @if $type==fade {
      $dir: "top";
    }

    @else if $type==slide {
      $dir: "center";
    }
  }

  position: relative;
  display: inline-block;
  text-decoration: none;

  &::after {
    position: absolute;
    left: 0;
    content: "";
    width: 100%;
    height: $weight;
    background: $color;

    @if $type==fade {
      transition: 0.3s;
      opacity: 0;
      visibility: hidden;

      @if $dir==bottom {
        bottom: $weight * -4;
      }

      @else {
        bottom: $weight;
      }
    }

    @else if $type==slide {
      bottom: $weight * -2;
      transform: scale(0, 1);
      transition: transform 0.3s;

      @if $dir==left-right or $dir==right-in-left {
        transform-origin: left top;
      }

      @else if $dir==right-left or $dir==left-in-right {
        transform-origin: right top;
      }

      @else {
        transform-origin: center top;
      }
    }
  }

  &:hover::after {
    @if $type==fade {
      bottom: $weight * -2;
      opacity: 1;
      visibility: visible;
    }

    @else if $type==slide {
      @if $dir==left-in-right {
        transform-origin: left top;
      }

      @else if $dir==right-in-left {
        transform-origin: right top;
      }

      transform: scale(1, 1);
    }
  }
}

//使い方
// Fade Top
//.example01 a {
//  @include hover-underline('fade', 'top');
//}
// Fade Bottom
//.example02 a {
//  @include hover-underline('fade', 'bottom');
//}
// Left to Right
//.example03 a {
//  @include hover-underline('slide', 'left-right');
//}
// Right to Left
//.example04 a {
//  @include hover-underline('slide', 'right-left');
//}
// Left in Right
//.example05 a {
//  @include hover-underline('slide', 'left-in-right');
//}
// Right in Left
//.example06 a {
//  @include hover-underline('slide', 'right-in-left');
//}
// Center
//.example07 a {
//  @include hover-underline('slide', 'center');
//}
//画像リンク
@mixin image-link() {
  @include mq-up(md) {
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.8;
    }
  }
}

//画像リンク
@mixin text-link() {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

@mixin image-link_zoom() {
  figure {
    overflow: hidden;

    img {
      transition: transform .2s;
    }
  }

  &:hover {
    img {
      transform: scale(1.1, 1.1);
    }
  }
}

//画像フィット
@mixin imgFit() {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  picture {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

//YOUTUBE埋め込み
@mixin youtube() {
  width: 100%;
  padding-top: 56.25%;
  position: relative;

  iframe {
    position: absolute;
    top: 0;
    right: 0;
    width: 100% !important;
    height: 100% !important;
  }
}

//ボックスシャドウ
@mixin boxshadow() {
  box-shadow: 10px 10px 10px rgba(#000, 0.2);
}

//ドット背景
@mixin dot-bg($wrap: s.$light-blue, $alpha: 0.1) {
  background-color: transparent;
  background-image:
    radial-gradient(rgba(s.$yellow, 0.2) 3.5px, transparent 3.75px),
    radial-gradient(rgba(s.$yellow, 0.2) 3.5px, transparent 3.75px);
  background-size: 20px 20px;
  background-position: 0 0, 10px 10px;
}

//事業ページ店舗情報スタイリング

@mixin facility($color) {
  .item {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding-bottom: 50px;
    border-bottom: 4px dotted $color;
    margin-bottom: 50px;

    @include b.mq-down(md) {
      display: block;
      padding-bottom: 40px;
      margin-bottom: 40px;
    }

    &:nth-last-of-type(1) {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
    }

    .photo {
      width: percentage(405px / 950px);
      display: flex;
      justify-content: space-between;
      align-self: baseline;
      flex-wrap: wrap;

      @include b.mq-down(md) {
        width: 100%;
      }

      .img {
        width: 32%;

        &:nth-of-type(1) {
          width: 100%;
        }

        img {
          border-radius: 15px;
          @include boxshadow();
        }
      }
    }

    .text {
      width: calc(100% - percentage(435px / 950px));

      @include b.mq-down(md) {
        width: 100%;
        margin-bottom: 40px;
      }

      .name {
        font-size: 2.2rem;
        font-weight: 700;
        letter-spacing: 2px;
        color: $color;
        margin-bottom: 35px;

        @include b.mq-down(md) {
          font-size: 1.7rem;
        }
      }

      .info {
        dl {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin-bottom: 15px;

          &.hp-link,
          &.sns {
            align-items: center;

            dd {
              a {
                color: $color;
                text-decoration: underline;
              }
            }
          }

          dt {
            width: 95px;
            color: $color;
            font-weight: 700;
            border: 1px solid $color;
            border-radius: 10px;
            background: #fff;
            padding: 2px 0;
            text-align: center;
            font-size: 1.2rem;
            letter-spacing: 0.5px;

          }

          dd {
            width: calc(100% - 110px);
            padding: 2px 0;

            a {
              color: $color;
              text-decoration: underline;
            }

            .banner {
              display: block;
              width: 100%;
              max-width: 236px;
            }

            .sns-icon {
              display: block;
              width: 36px;
            }
          }
        }
      }
    }
  }
}
