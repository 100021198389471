@use "../global" as g;

.p-facility {
  .pagehead-sec {
    position: relative;
    z-index: 1;
  }

  .article-sec {
    background-image: url(../images/common/bg.svg);
    background-position: center top;
    background-repeat: repeat-y;
    background-size: 1560px;
    padding-bottom: 50px;
    position: relative;

    @include g.mq-down(md) {
      padding-bottom: 0px;
      background-image: url(../images/common/bg_sp.svg);
      background-size: 100%;
    }

    .inner {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: flex-start;
      position: relative;
      z-index: 2;

      @include g.mq-down(md) {
        display: block;
      }

      aside {
        width: percentage(266px / 1080px);
        background: #DFF0F9;
        border-radius: 15px;
        @include g.boxshadow();

        @include g.mq-down(md) {
          width: 100%;
          margin-bottom: 50px;
        }

        .midashi {
          font-size: 1.6rem;
          font-weight: 700;
          color: g.$blue;
          padding: 15px;
          border-bottom: 2px solid g.$blue;
          line-height: 1;
          letter-spacing: 2px;
        }

        .cat-list {
          color: g.$blue;
          padding: 15px;
          font-size: 1.6rem;
          font-weight: 500;
          line-height: 2;

          a {
            display: flex;
            align-items: center;

            &::before {
              content: "";
              display: inline-block;
              width: 16px;
              height: 16px;
              background: #fff;
              border: 1px solid g.$blue;
              margin-right: 10px;

              @include g.mq-down(md) {
                margin-right: 5px;
              }
            }

            &.current,
            &:hover {
              &::before {
                background: g.$yellow;
              }
            }
          }

          li {
            &>ol {
              margin-left: calc(1em + 10px);

              @include g.mq-down(md) {
                display: flex;
                flex-wrap: wrap;

                li {
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }

      .main-body {
        width: percentage(760px / 1080px);

        @include g.mq-down(md) {
          width: 100%;
        }

        .midashi {
          width: 100%;
          max-width: 100%;
          text-align: left;
          padding-left: 30px;
          padding-right: 30px;
          margin-bottom: 50px;

          @include g.mq-down(md) {
            margin-bottom: 30px;
          }
        }

        .article {
          border: 4px dotted g.$light-blue;
          border-radius: 35px;
          padding: 50px;
          margin-bottom: 50px;

          @include g.mq-down(md) {
            padding: 20px;
            margin-bottom: 30px;
          }

          &:nth-last-of-type(1) {
            margin-bottom: 0;
          }

          .name {
            font-size: 2.3rem;
            font-weight: 700;
            color: g.$blue;
            border-bottom: 4px solid g.$blue;
            letter-spacing: 1px;
            padding-bottom: 10px;
            margin-bottom: 10px;

            @include g.mq-down(md) {
              font-size: 1.8rem;
            }
          }

          .place {
            line-height: 2;
            margin-bottom: 40px;

            @include g.mq-down(md) {
              margin-bottom: 20px;
            }

            .tel {
              a {
                @include g.mq-up(md) {
                  pointer-events: none;
                }

                @include g.mq-down(md) {
                  color: g.$blue;
                  font-weight: 700;
                  text-decoration: underline;
                }

              }
            }

            .map {
              margin-top: 20px;

              @include g.mq-down(md) {
                margin-top: 10px;
              }

              .btn {
                display: inline-block;
                background: g.$light-blue;
                border: 2px solid g.$light-blue;
                color: #fff;
                position: relative;
                padding: 0px 70px;
                border-radius: 9999px;
                transition: background .2s, color .2s;

                span {
                  position: absolute;
                  top: 50%;
                  left: 10px;
                  transform: translateY(-50%);

                  svg {
                    width: 14px;
                    fill: #fff;
                    transition: fill .2s;
                  }
                }

                &:hover {
                  color: g.$light-blue;
                  background: #fff;

                  span {
                    svg {
                      fill: g.$light-blue;
                    }
                  }
                }
              }
            }
          }

          .shop-img {
            margin-bottom: 40px;

            @include g.mq-down(md) {
              margin-bottom: 20px;
            }

            figure {
              img {
                border-radius: 30px;
              }
            }
          }

          .spec {
            margin-bottom: 25px;

            dl {
              display: flex;
              justify-content: space-between;

              @include g.mq-down(md) {
                display: block;
              }

              dt {
                width: 120px;
                font-size: 1.6rem;
                font-weight: 700;
                color: g.$blue;
                padding: 9px 0 0;
                letter-spacing: 1px;

                @include g.mq-down(md) {
                  width: 100%;
                  margin-bottom: 15px;

                }
              }

              dd {
                width: calc(100% - 140px);
                display: flex;
                flex-wrap: wrap;

                @include g.mq-down(md) {
                  width: 100%;
                  justify-content: space-between;
                }

                p {
                  width: 50%;
                  max-width: 220px;
                  display: flex;
                  align-items: center;
                  margin-bottom: 15px;

                  @include g.mq-down(md) {
                    width: 49.5%;
                    max-width: none;
                    margin-bottom: 10px;
                  }

                  .icon {
                    width: 45px;
                    height: 45px;
                    margin-right: 10px;

                    @include g.mq-down(md) {
                      width: 38px;
                      height: 38px;
                    }

                    img {
                      border-radius: 10px;
                    }

                  }

                  .text {
                    width: calc(100% - 55px);
                    letter-spacing: 1px;

                    @include g.mq-down(md) {
                      font-size: 1.25rem;
                      letter-spacing: 0px;
                    }

                  }

                }
              }
            }
          }

          .space {
            .icon {
              svg {
                fill: #B3B3B3;

                &.active {
                  fill: #FF501E;
                }
              }
            }

          }

          .howtopay,
          .other {
            margin-bottom: 25px;

            dl {
              display: flex;
              justify-content: space-between;

              @include g.mq-down(md) {
                display: block;
              }

              dt {
                width: 120px;
                font-size: 1.6rem;
                font-weight: 700;
                color: g.$blue;
                padding: 9px 0 0;
                letter-spacing: 1px;

                @include g.mq-down(md) {
                  width: 100%;
                  margin-bottom: 15px;
                }
              }

              dd {
                width: calc(100% - 140px);
                padding-top: 10px;
                font-size: 1.5rem;

                @include g.mq-down(md) {
                  width: 100%;
                }
              }
            }
          }

          .site {
            margin-bottom: 25px;

            dl {
              display: flex;
              justify-content: space-between;

              @include g.mq-down(md) {
                display: block;
              }

              dt {
                width: 120px;
                font-size: 1.6rem;
                font-weight: 700;
                color: g.$blue;
                padding: 9px 0 0;
                letter-spacing: 1px;

                @include g.mq-down(md) {
                  width: 100%;
                  margin-bottom: 15px;
                }
              }

              dd {
                width: calc(100% - 140px);
                display: flex;
                flex-wrap: wrap;

                @include g.mq-down(md) {
                  width: 100%;
                  justify-content: space-between;
                }

                a {
                  width: 100%;
                  display: flex;
                  align-items: flex-end;
                  flex-wrap: wrap;
                  color: g.$blue;
                  font-weight: 700;

                  @include g.mq-down(md) {
                    display: block;
                  }
                }

                figure {
                  max-width: 60%;
                  margin-right: 15px;

                  //margin-bottom: 0.5em;
                  @include g.mq-down(md) {
                    max-width: 100%;
                    margin-right: 0;
                    margin-bottom: 0.5em;
                  }

                  img {
                    width: inherit;
                    max-width: 100%;
                  }
                }

                p {
                  width: 160px;
                  //width:calc(40% - 15px);
                  display: flex;
                  align-items: center;
                  margin-bottom: 15px;

                  span {
                    &.icon {
                      margin-left: 5px;

                      svg {
                        width: 16px;
                        height: 16px;
                        fill: g.$blue;
                      }
                    }
                  }
                }
              }
            }
          }

          .sns {
            dl {
              display: flex;
              justify-content: space-between;

              @include g.mq-down(md) {
                display: block;
              }

              dt {
                width: 120px;
                font-size: 1.6rem;
                font-weight: 700;
                color: g.$blue;
                padding: 5px 0 0;
                letter-spacing: 1px;

                @include g.mq-down(md) {
                  width: 100%;
                  margin-bottom: 15px;
                }
              }

              dd {
                width: calc(100% - 140px);
                display: flex;
                flex-wrap: wrap;

                @include g.mq-down(md) {
                  width: 100%;
                }

                a {
                  display: block;
                  width: 36px;
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
    }

    .parallax {

      .parallax1 {
        position: absolute;
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
        width: 1680px;
        height: 110%;
        z-index: 0;
        background-image: url(../images/facility/parallax_article-01_pc.svg);
        background-position: top center;
        background-repeat: repeat-y;
        background-size: 1680px;

        @include g.mq-down(md) {
          background-image: url(../images/facility/parallax_article-01_sp.svg);
          width: calc(g.spvw(750) + 175px);
          background-size: calc(g.spvw(750) + 175px);
          background-repeat: repeat-y;
        }
      }

      .parallax2 {
        position: absolute;
        top: -50px;
        left: 50%;
        transform: translateX(-50%);
        width: 1520px;
        height: 110%;
        z-index: 0;
        background-image: url(../images/facility/parallax_article-02_pc.svg);
        background-position: top center;
        background-repeat: repeat-y;
        background-size: 1520px;

        @include g.mq-down(md) {
          top: 100px;
          background-image: url(../images/facility/parallax_article-02_sp.svg);
          width: calc(g.spvw(750) + 9px);
          background-size: calc(g.spvw(750) + 9px);
          background-repeat: repeat-y;
          z-index: 0;
        }
      }
    }
  }
}
